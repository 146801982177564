import React, { createContext, useReducer, useEffect } from 'react';

export const AuthContenxt = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { user: action.payload };
        case 'LOGOUT':
            return { user: null };
        default:
            return state;
    }
}

export const AuthContenxtProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null
    })
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) dispatch({ type: 'LOGIN', payload: user })
    }, [])


    return (
        <AuthContenxt.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContenxt.Provider>
    )
}