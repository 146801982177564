import React from 'react'
import { Box, styled } from '@mui/material'
import SearchBarInput from '../master/components/SearchBarInput';
import MasterAppBar from '../master/components/MasterAppBar'
import Event from '../dashbord/components/Event';
import Footer from '../master/components/Footer';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

function MyEvents() {
    const events = [
        {
            id: 1,
            num: '06',
            month: 'January',
            description: "this is the event description"
        },
        {
            id: 2,
            num: '06',
            month: 'January',
            description: "this is the event description"
        }, {
            id: 3,
            num: '06',
            month: 'January',
            description: "this is the event description"
        }, {
            id: 4,
            num: '06',
            month: 'January',
            description: "this is the event description"
        }, {
            id: 5,
            num: '06',
            month: 'January',
            description: "this is the event description"
        }
    ]

    const EventsBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '90vw',
        gap: '20px',
        margin: 'auto',
        marginTop: '4rem'

    }))
    return (
        <ScopedCssBaseline>
            <Box>
                <MasterAppBar crumb={true} />
                <SearchBarInput />
                <EventsBox>
                    {events.map(event => (
                        <Event data={event} />
                    ))}
                </EventsBox>
                <Footer />
            </Box>
        </ScopedCssBaseline>

    )
}

export default MyEvents