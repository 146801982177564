import React from 'react'
import { Box, styled } from '@mui/material'
import SearchBarInput from '../master/components/SearchBarInput';
import MasterAppBar from '../master/components/MasterAppBar'
import CourseCard from '../dashbord/components/CourseCard';
import Footer from '../master/components/Footer';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

function MyCourses() {
    const courses = [
        {
            id: 1,
            image: './assets/course.jpg',
            title: 'PHP course New Arrival. Do not wait to enroll',
            progress: 50
        },
        {
            id: 2,
            image: './assets/course.jpg',
            title: 'Laravel course New Arrival',
            progress: 30
        }, {
            id: 3,
            image: './assets/course.jpg',
            title: 'Matlab course New Arrival',
            progress: 80
        }, {
            id: 4,
            image: './assets/course.jpg',
            title: 'Matlab course New Arrival',
            progress: 80
        }, {
            id: 5,
            image: './assets/course.jpg',
            title: 'Matlab course New Arrival',
            progress: 80
        }
    ]

    const CoursesBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '90vw',
        gap: '20px',
        margin: 'auto',
        marginTop: '4rem'

    }))
    return (
        <ScopedCssBaseline>
            <Box>
                <MasterAppBar crumb={true} />
                <SearchBarInput />
                <CoursesBox>
                    {courses.map(course => (
                        <CourseCard key={course.id} data={course} />
                    ))}
                </CoursesBox>
                <Footer />
            </Box>
        </ScopedCssBaseline>

    )
}

export default MyCourses