import React from 'react'
import { Box, styled, Avatar, Typography, useTheme } from '@mui/material'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { tokens } from '../../../layout/theme';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import { getInitialForAvatar } from '../../virtualClassRoom/helpers/helperMethods/getInitials';
function Comment() {
    const theme = useTheme()
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const colors = tokens(theme.palette.mode)
    const CommentContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '16px',
        alignItems: 'center',
        backgroundColor: colors.primary[100],
        border: `1px solid ${colors.primary[100]}`,
        borderRadius: '10px'
    }))
    const CommentInfo = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '16px',
        alignItems: 'center',

    }))
    const TutorInfo = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    }))
    const VoteBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '16px',
        alignItems: 'center',

    }))
    return (
        <CommentContainer>
            <CommentInfo>
                {user&&<Avatar alt={user.full_name} src='./assets/2.jpg' >{getInitialForAvatar(user.full_name)}</Avatar>}
                <TutorInfo>
                    <Typography variant='h5'>Hadi Dahi</Typography>
                    <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>Ok I this is right</Typography>
                </TutorInfo>
            </CommentInfo>
            <VoteBox>
                <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}  >14 Votes</Typography>
                <ArrowCircleUpIcon />
            </VoteBox>
        </CommentContainer>
    )
}

export default Comment