
import { Avatar, Box, IconButton, Typography, styled, useTheme } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { BaseUrl } from '../../../config/API';
import MicIcon from '@mui/icons-material/Mic';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicOffIcon from '@mui/icons-material/MicOff';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import { getInitialForAvatar } from './../helpers/helperMethods/getInitials';


const StudentMeetingCard = ({ cardDimensions, attendee, toturStream, handleStreamTutorAudio, handleStreamTutorCam, handleStreamStudentAudio, handleStreamStudentCam }) => {
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const theme = useTheme();

    const videoRef = useRef()
    const avatarRef = useRef();
    const ContainerBox = styled(Box)({
        width: `${cardDimensions.cardWidth}px`,
        height: `${cardDimensions.cardHeight}px`,
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        flexDirection: 'column'
    })
    const CamSection = styled(Box)({
        flex: 4,
        width: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    })
    const InfoSection = styled(Box)({
        flex: 1,
        width: '100%',
        borderTop: `2px solid ${theme.palette.background.gray}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center'
    })
    const ButtonsSection = styled(Box)({

        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center'
    })

    useEffect(() => {
        if (attendee && avatarRef.current && videoRef.current) {
            if (attendee.stream) {
                const videoTrack = attendee.stream.getVideoTracks()[0];
                if (videoTrack) {
                    avatarRef.current.style.display = 'none';
                    videoRef.current.style.display = 'inline-block';
                } else {
                    avatarRef.current.style.display = 'flex';
                    videoRef.current.style.display = 'none';
                }
                videoRef.current.srcObject = attendee.stream;
            }
            if (toturStream && avatarRef.current && videoRef.current) {
                if (attendee.user_type !== 0) {
                    const videoTrack = toturStream.getVideoTracks()[0];
                    if (videoTrack) {
                        avatarRef.current.style.display = 'none';
                        videoRef.current.style.display = 'inline-block';
                    } else {
                        avatarRef.current.style.display = 'flex';
                        videoRef.current.style.display = 'none';
                    }
                    videoRef.current.srcObject = toturStream;

                }
            }

        }
    }, [attendee, toturStream, cardDimensions])

    const handleStreamAudio = () => {
        if (attendee.user_type !== 0) {
            handleStreamTutorAudio();
        } else {
            handleStreamStudentAudio(attendee)
        }

    }

    const handleStreamCam = async () => {
        if (attendee.user_type !== 0) {
            handleStreamTutorCam();
        } else {
            handleStreamStudentCam(attendee)
        }
    }
    return (
        <ContainerBox>
            <CamSection>
                <Avatar ref={avatarRef} sx={{ width: cardDimensions.cardWidth * 0.3, height: cardDimensions.cardWidth * 0.3, fontSize: cardDimensions.cardWidth / 150 + 'rem' }} alt={attendee?.user_name} src={`${BaseUrl}${attendee?.user_image}`} >{getInitialForAvatar(attendee?.user_name)}</Avatar>
                <video ref={videoRef} autoPlay muted={attendee.user_id === user.id} style={{ width: '100%', height: '100%', display: 'none' }} />
            </CamSection>
            <InfoSection>
                <Typography sx={{ margin: '0.5rem', fontSize: (cardDimensions.cardWidth / 400) > 1 ? cardDimensions.cardWidth / 400 + 'rem' : 1 + 'rem', color: attendee?.user_type !== 0 ? theme.palette.secondary.main : theme.palette.text.default, direction: theme.direction }}>{attendee?.user_name}</Typography>
                <ButtonsSection>
                    {(user.id === attendee.user_id) && <IconButton sx={{ color: theme.palette.secondary.main }} onClick={() => handleStreamAudio()}>
                        {attendee.micEnabled ? (<MicOffIcon />) : (<MicIcon />)}
                    </IconButton>}
                    {user.id === attendee.user_id && <IconButton sx={{ color: theme.palette.secondary.main }} onClick={() => handleStreamCam()}>
                        {attendee.camEnabled ? (<VideocamOffIcon />) : (<VideoCameraFrontIcon />)}
                    </IconButton>}
                </ButtonsSection>
            </InfoSection>
        </ContainerBox >
    )
}

export default StudentMeetingCard