import React, { useState, createContext } from "react";

export const BreadCrumbContext = createContext({
    breadCrumbSetter: () => { },
    breadCrumbAdder: () => { },
    breadCrumbState: [],
});

export const BreadCrumbContextProvider = ({ children }) => {
    const [breadCrumbState, setBreadCrumbState] = useState([{ id: 1, title: "dashboard", route: "/", translatable: true }])
    const breadCrumbSetter = (id) => {
        if (id === 0) {
            setBreadCrumbState([{ id: 1, title: "dashboard", route: "/", translatable: true }])
            return
        }
        setBreadCrumbState((prev) => prev.filter(b => b.id <= id));
    }
    const breadCrumbAdder = (data) => {
        setBreadCrumbState((prev) => [...prev, { ...data, id: prev.length + 1 }]);
    }
    return (
        <BreadCrumbContext.Provider value={{ breadCrumbSetter, breadCrumbAdder, breadCrumbState }} >
            {children}
        </BreadCrumbContext.Provider>
    )
}