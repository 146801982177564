import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from './../auth/hooks/useAuthContext';
import { useLogout } from '../auth/hooks/useLogout';
import { Backdrop, CardMedia } from '@mui/material';
import API from '../../config/API';

function PrepareExternalLinkAttendance() {
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const {session_id, user_token} = useParams();
    const[isLoading, setIsLoading] = useState(true)
    const { dispatch } = useAuthContext();
    const { logout } = useLogout();
    const navigate = useNavigate();

    const checkUserAccess = useCallback(() => {
        
        API.get(`check_user_access?sess_id=${session_id}&user_token=${user_token}`, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then(data => {
            console.log("the data is ", data)
            if (data.status === 200) {
                localStorage.setItem('user', JSON.stringify(data.data.details))
                dispatch({ type: 'LOGIN', payload: data.data.details })
                if(data?.data?.details?.user_type === 1){
                    navigate(`/class_room/${session_id}/${data?.data?.details?.id}`)
                }else{
                    navigate(`/class_room/${session_id}/${false}`)
                }
            } else {
                setIsLoading(false)
                navigate("/")
                throw Error('Someting wrong happened')
            }
            setIsLoading(false)
        }).catch(err => { console.log("the error is ", err); setIsLoading(false) })
    },[session_id, user_token, user])


    useEffect(()=>{
        if(session_id && user_token) {
            if(user){
                if(user?.token===user_token){
                    navigate(`/session/${session_id}`)
                }else{
                    //logout
                    logout();
                }
            }else{//send api to check if user has access on this session
                console.log("the session id user token", session_id, user_token)
                checkUserAccess();
            }
        }
    },[session_id, user_token, user, checkUserAccess])
  return (
    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CardMedia component="img"
                        image="./assets/loading.gif"
                        sx={{ width: '10rem' }}
                    />
                </Backdrop>
  )
}

export default PrepareExternalLinkAttendance