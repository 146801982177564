/* eslint-disable react/prop-types */
import React from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'
import Radio from '@mui/material/Radio';
import { tokens } from './../../../layout/theme';


function CourseFinalTest({ data }) {
    console.log("the data from quiz is ", data)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)
    const TestContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '2rem',
        padding: '10px'
    })
    const AnswersBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',

    })
    const AnswerBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: `2px solid ${theme.palette.background.gray}`,
        borderRadius: '5px'

    })
    return (
        <Box>
            {data.map((question, index) => (
                <TestContainer key={index}>
                    <Typography>{question.questionId}. &nbsp; {question.question}</Typography>
                    <AnswersBox>
                        {question.options.map((answer, index) => (
                            <AnswerBox key={index} sx={{ backgroundColor: answer.status ? colors.primary[200] : theme.palette.background.default }}>
                                <Radio
                                    // checked={answer.status}
                                    // disabled={!answer.status}
                                    value={answer.id}
                                    name="radio-buttons"
                                />
                                <Typography>{answer.option_text}</Typography>
                            </AnswerBox>
                        ))}
                    </AnswersBox>
                </TestContainer>
            ))}

        </Box>

    )
}

export default CourseFinalTest