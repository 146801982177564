import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const lang = JSON.parse(localStorage.getItem('lang'));
i18n
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: lang?.lang ?? 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            en: {
                translation: {
                    part:'Part',
                    underProcess:"Session videos is under process. Please try again later",
                    alreadyRegistered:"You are already registered, please log out first.",
                    fullName:"Your Name",
                    fullNameRequired:"Name is required",
                    fullNameShort:"Name is too short",
                    fullNameLong:"Name is too long",
                    joinMeeting:"Join Meeting",
                    downloadAppMessage:"You need to download the platform desktop app to use the student share screen feature",
                    downloadApp:"Download",
                    privatMessage: "Private Message",
                    fiveMinLeft: "Warning! 5 Minutes left of the session duration ",
                    preparingEndSession: "Preparing to end session in: ",
                    extend10: "Extend for 10 minutes?",
                    extend5: "Extend for 5 minutes?",
                    ended: "Ended",
                    started: "Started",
                    notStarted: "Not Started",
                    asteacher: 'As Instructor',
                    asstudent: 'As Attendee',
                    startSession: 'Start Session',
                    socketErrored: 'Failed to connect to our servers, please check your internet connection. Trying to reconnet...',
                    not_authorized: "Someone logged in with your account, please log in again.",
                    noCoursFound: "No Courses Found",
                    createCourse: "Create New Course",
                    exploreCourses: "Explore Our Courses",
                    startMeeting: "Start Meeting",
                    endMeeting: "End Meeting",
                    resume: "Resume",
                    stopSession: "End Session",
                    undo: "Undo",
                    redo: "Redo",
                    chooseAScreen: "Please choose screen to share:",
                    chooseMicVideoSettings: "Choose what do you want to stream:",
                    micOnly: "Audio only",
                    micAndVideo: "Video and audio",
                    dashboard: "Dashboard",
                    events: "Upcoming Events",
                    share_screen: "Share Screen",
                    board: "Board",
                    noMoreTabs: "You can't open more tabs",
                    ok: "OK",
                    close: "Cancel",
                    error500: "Somthing went wrong!!",
                    phonenumber: 'Phone Number',
                    password: "Password",
                    rememberme: "Remember me",
                    login: "Log In",
                    forgotpassword: "Forgot Password?",
                    profile: "Profile",
                    logout: "Log Out",
                    messages: "Messages",
                    notifications: "Notifications",
                    search: "Search ...",
                    mycourses: "My Meetings",
                    more: "More",
                    poweredby: "Powered By",
                    sp: "SP",
                    buy: "BUY",
                    attachments: "Attachments",
                    links: "Links",
                    finaltest: "Final Test",
                    descussion: "Descussion",
                    quiz: "Quiz",
                    comments: "Comments",
                    reviews: "Reviews",
                    addreview: "Add Review",
                    yourreview: "Your Review ...",
                    sendmessage: "Send Message",
                    poll: "POLL",
                    attendeelist: "Attendee List",
                    pause: "Pause Recording",
                    elapsed: "Elapsed Time",
                    remaining: "Remaining Time",
                    uploaderheadertext: "Upload Board Content",
                    or: "or",
                    selectfiles: "to select file",
                    clickhere: "Click here",
                    draganddrop: "Drag and drop",
                    privacypolicy: "Privacy Policy",
                    support: "Support",
                    contactus: "Contact us",
                    chatbox: 'Comments',
                    private_message_to: 'Send private message to ',
                    mic_devices: "Please Select Mic Device",
                    cam_devices: "Please Select Camera Device",
                    test_audio: 'Test microphone device',
                    test_video: "Test camera",
                    cam_mic_settings: "Camera and Microphone Settings",
                    ask_for_mic: "The teacher wants to give you the ability to talk using the mic",
                    course_teach_web:"You can't use the web app to give the session. Please install the desktop app from our website to proceed",
                    apologize: "I apologize",
                    accept: "Accept",
                    noAttachments:"There is no attachments for this course.",
                    noLinks:"There is no links for this course.",
                    go_to_website:"Go to website",
                    waitingForApproval:"Waiting for instructor to admit your request",
                    admitionRejected:"Your request is rejected. Please refresh the page to retry"
                }
            },
            ar: {

                translation: {
                    part:'الجزء',
                    underProcess:"فيدوهات الجلسة لا تزال قيد المعالجة. الرجاء المحاولة في وقت لاحق",
                    alreadyRegistered:"لديك حساب بالفعل، الرجاء تسجيل الخروج اولاً",
                    fullName:"اسمك",
                    fullNameRequired:"حقل الاسم مطلوب",
                    fullNameShort:"الاسم قصير جدا",
                    fullNameLong:"الاسم طويل جدا",
                    joinMeeting:"انضم للاجتماع",
                    downloadAppMessage:"تحتاج ميزة مشاركة شاشة الطالب الى تطبيق الديسكتوب الخاص بالمنصة، يرجى تحميل التطبيق للمتابعة",
                    downloadApp:"تحميل",
                    privatMessage: "رسالة خاصة",
                    fiveMinLeft: "تحذير! تبقى خمسة دقائق من وقت الجلسة ",
                    preparingEndSession: "تحضير انهاء الجلسة خلال: ",
                    extend10: "تمديد 10 دقائق؟",
                    extend5: "تمديد 5 دقائق؟",
                    ended: "انتهت",
                    started: "بدأت",
                    notStarted: "لم تبدأ",
                    asteacher: 'كمُحاضر',
                    asstudent: 'كطالب / مدعوّ',
                    startSession: 'ابدأ الجلسة',
                    socketErrored: 'فشل الاتصال بالخادم، الرجاء التأكد من اتصالك بالانترنت. يجري اعادة محاولة الاتصال...',
                    not_authorized: "تم تسجيل الدخول من حسابك من جهاز آخر يرجى إعادة تسجيل الدخول.",
                    noCoursFound: "لا يوجد أية كورسات",
                    exploreCourses: "ألقِ نظرة على دوراتنا",
                    createCourse: "أنشئ دورة جديدة",
                    startMeeting: "بدء الاجتماع",
                    endMeeting: "إنهاء الاجتماع",
                    resume: "متابعة",
                    stopSession: "إنهاء الجلسة",
                    undo: "تراجع",
                    redo: "عودة عن التراجع",
                    chooseAScreen: "الرجاء اختيار شاشة للمشاركة:",
                    chooseMicVideoSettings: "اختر ماذا تريد ان تشارك",
                    micOnly: "الصوت فقط",
                    close: "إلغاء",
                    micAndVideo: "الصوت والصورة",
                    dashboard: "لوحة التحكم",
                    events: "الاحداث القادمة",
                    share_screen: "مشاركة الشاشة",
                    board: "نافذة",
                    noMoreTabs: "لا يمكنك فتح نوافذ جديدة",
                    ok: "حسناً",
                    error500: "حدث خطأ ما",
                    phonenumber: 'رقم الجوال',
                    password: "كلمة السر",
                    rememberme: "تذكرني",
                    login: "تسجيل الدخول",
                    forgotpassword: "نسيت كلمة المرور؟",
                    logout: "تسجيل الخروج",
                    messages: "الرسائل",
                    notifications: "الاشعارات",
                    profile: "الصفحة الشخصية",
                    search: "ابحث ...",
                    mycourses: "اجتماعاتي",
                    more: "المزيد",
                    poweredby: "طور من قبل",
                    sp: "ل.س",
                    buy: "شراء",
                    attachments: "المرفقات",
                    links: "المراجع",
                    finaltest: "الاختبار النهائي",
                    descussion: "النقاش",
                    quiz: "الاختبار",
                    comments: "التعليقات",
                    reviews: "التقييمات",
                    addreview: "اضافة تقييم",
                    yourreview: "ضع تقييمك هنا ...",
                    sendmessage: "ارسل رسالة",
                    poll: "سؤال",
                    attendeelist: "قائمة الحاضرين",
                    pause: "أيقاف التسجيل مؤقتاً",
                    elapsed: "الوقت المنقضي",
                    remaining: "الوقت المتبقي",
                    uploaderheadertext: "حمل محتوى اللوح",
                    or: "أو",
                    selectfiles: "لاختيار الملف",
                    clickhere: "إنقر هنا",
                    draganddrop: "اسحب وافلت",
                    privacypolicy: "سياسة الخصوصية",
                    support: "الدعم الفني",
                    contactus: "اتصل بنا",
                    chatbox: 'التعليقات',
                    private_message_to: 'ارسال رسالة خاصة لـ',
                    mic_devices: "الرجاء اختيار الميكروفون",
                    cam_devices: "الرجاء اختيار الكاميرا",
                    test_audio: 'تجريب الميكروفون',
                    test_video: "تجريب الكاميرا",
                    cam_mic_settings: "اعدادات الكاميرا والميكروفون",
                    ask_for_mic: "يرغب المحاضر باعطائك أحقية الكلام باستخدام الميكروفون",
                    apologize: "أعتذر",
                    accept: "موافق",
                    noAttachments:"لا يتوفر مرفقات لهذه الدورة",
                    noLinks:"لا يتوفر روابط لهذه الدورة",
                    course_teach_web:"لا يمكنك استخدام تطبيق الويب لاعطاء الدورات. الرجاء تحميل تطبيق الديسكتوب من موقعنا للمتابعة",
                    go_to_website:"الذهاب للموقع",
                    waitingForApproval:"بانتظار المحاضر للموافقة على طلب انضمامك للاجتماع",
                    admitionRejected:"تم رفض طلب انضمامك يرجى تحديث الصفحة لاعادة المحاولة"
                }   
            },
        }
    });

export default i18n;