import { Box, ScopedCssBaseline, styled } from '@mui/material'
import React, { useEffect } from 'react'
import MasterAppBar from '../master/components/MasterAppBar'
import { socket } from '../../config/SocketApi';
const ClassRoomDashboard = () => {

    const DashboardContainer = styled(Box)({
        // height: '100vh',
        // width: '100vw',
        flexDirection: 'column'
    })
    useEffect(() => {
        socket.on("final-video", (data) => {
            console.log(data.file_link)
        })
        return () => {
            socket.off("final-video")
        }
    }, [])
    return (
        <ScopedCssBaseline>
            <DashboardContainer>
                <MasterAppBar />
            </DashboardContainer>
        </ScopedCssBaseline>

    )
}

export default ClassRoomDashboard