import React from 'react'
import { alpha, Box, InputBase, styled, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const SearchBarInput = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', marginTop: '50px', width: '100%' }}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    sx={{ width: '100%', padding: theme.direction === 'ltr' ? '0.5rem' : '1rem' }}
                    placeholder={t("search")}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
        </Box>

    )
}

//Styled elements
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.gray, 0.5),
    '&:hover': {
        backgroundColor: alpha(theme.palette.background.gray, 1),
    },
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '70%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '45%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '40%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        paddingRight: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));
export default SearchBarInput;
