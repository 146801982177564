import { Box, Typography, styled, useTheme } from '@mui/material'
import React from 'react'
function Event({ data }) {
    console.log("the event data is ", data)
    const theme = useTheme();
    return (
        <ContainerBox key={data.id}>
            <LeftBox>
                <DateBox>
                    <Typography variant='h4' align='center' alignItems='center'>{1}</Typography>
                </DateBox>
            </LeftBox>
            <RightBox>
                <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>{data.date}</Typography>
                <Typography variant='h5' sx={{ color: theme.palette.text.default }}>{data.session[0].title}</Typography>
            </RightBox>
        </ContainerBox>
    )
}


const ContainerBox = styled(Box)(({ theme }) => ({
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor:
        theme.palette.background.gray,
    borderRadius: '0.5rem',
    width: '100%',
    // maxWidth: '20rem',
    maxHeight: '5rem'
}));

const LeftBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    flex: 1,

}));


const RightBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 3
}));


const DateBox = styled(Box)(({ theme }) => ({
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
    width: '3rem',
    height: '3rem',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '0.65rem',
    // display: 'flex',
    // justifyContent: 'center',
    // justifyItems: 'center',

}));
export default Event