import { Box, Tab, Typography, Tabs } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import CourseCard from './CourseCard';
import { useTranslation } from 'react-i18next';
import { BreadCrumbContext } from '../../master/context/BreadCrumbContext';
import API from '../../../config/API';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import Skeleton from './helpers/Skeleton';
import { useLogout } from '../../auth/hooks/useLogout';
import SwipeableViews from 'react-swipeable-views';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function CourseCards() {
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();

    const [coursesAsTeacher, setCoursesAsTeacher] = useState([]);
    const [coursesAsStudent, setCoursesAsStudent] = useState([]);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const { logout } = useLogout();
    const { breadCrumbAdder, breadCrumbSetter } = useContext(BreadCrumbContext)
    const { t } = useTranslation();
    const AntTabs = styled(Tabs)(({ theme }) => ({
        marginTop: '2rem',
        borderBottom: '1px solid #e8e8e8',
        justifyContent: 'space-between',
        textAlign: 'center',
        // width: '100%',
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.text.default,
        }, '& .MuiTabs-flexContainer': {
            justifyContent: 'space-around',

        },
    }));

    const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        // width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.5em',
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
        '&.Mui-selected': {
            color: theme.palette.text.default,
            fontWeight: theme.typography.fontWeightMedium,
        },

    }));

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const ContainerBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        // gap: '1rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
        width: '100%',
        // marginTop: '2rem'
    }));

    const MoreBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',

    }));
    const InnerBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'flex-start',
        flex: 1,
        width: '100%'

    }));
    const NoContent = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20rem',
        padding: '2rem',
        // width: '100%'
    }));
    const handleLogOut = () => {
        logout();
        breadCrumbSetter(0)
        navigate("/")
    };
    const getTeacherCourseData = () => {
        setLoading(true)
        API.get('teacher/courses?page=1&limit=10', {
            headers: {
                Authorization: user.token,
                "Content-Type": 'application/json'
            }
        }).then(data => {
            if (data.status === 200 && data?.data?.data.length) {
                setCoursesAsTeacher(data.data.data)
            }
            setLoading(false)
        }).catch(err => {
            console.log("the axios error is ", err)
            if (err?.response?.status === 403 || err.code === 'ERR_NETWORK') {
                handleLogOut()
            }

            setLoading(false)
        })
    }
    const getEnrolledCourseData = () => {
        setLoading(true)
        API.get('cousres/enrolled?page=1&limit=10', {
            headers: {
                Authorization: user.token,
                "Content-Type": 'application/json'
            }
        }).then(data => {
            if (data.status === 200 && data?.data?.data.length) {
                setCoursesAsStudent(data.data.data)
            }
            setLoading(false)
        }).catch(err => {
            console.log("the axios error is ", err)
            if (err?.response?.status === 403 || err.code === 'ERR_NETWORK') {
                handleLogOut()
            }

            setLoading(false)
        })
    }
    useEffect(() => {
        if (user) {
            if (user.user_type === 0) {
                getEnrolledCourseData()
            } else {
                getTeacherCourseData()
                getEnrolledCourseData()

            }
        }
    }, [user])
    const handleNavigation = () => {
        breadCrumbAdder({ translatable: true, title: 'mycourses', route: '/all_courses' })
        navigate('/all_courses')
    }

    return (
        <>
            {user && <ContainerBox >
                <Typography variant='h2' sx={{ ml: '0.2rem', mb: '1rem', color: theme.palette.text.secondary }}>{t("mycourses")}</Typography>
                {(loading && !coursesAsStudent.length) ? <>
                    {Array(3).fill().map((_, index) => <Skeleton key={index} width={'20rem'} height={'8rem'} />)}
                </> :
                    <Box sx={{ width: '90%' }}>
                        {user.user_type !== 0 ?
                            <>
                                <AntTabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="secondary tabs example"
                                >
                                    <AntTab value={0} label={t("asteacher")} />
                                    <AntTab value={1} label={t("asstudent")} />
                                </AntTabs>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    <TabPanel value={value} index={0} dir={theme.direction}>
                                        <InnerBox>
                                            {coursesAsTeacher.length > 0 ?
                                                <>
                                                    {coursesAsTeacher.map(course => (
                                                        <CourseCard key={course.course_id}  data={course} />
                                                    ))}
                                                    {/* {coursesAsTeacher.length >= 4 &&
                                                        <MoreBox onClick={() => handleNavigation()}>
                                                            <Typography variant='h4' sx={{ color: theme.palette.primary.main }}>{t("more")} &nbsp; </Typography>
                                                            {theme.direction === 'ltr' ? (<ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />) : (<ArrowBackIcon sx={{ color: theme.palette.primary.main }} />)}
                                                        </MoreBox>
                                                    } */}

                                                </>
                                                : <NoContent>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/no_courses.png`} style={{ width: '15rem' }} alt={t("noCoursFound")} />
                                                    {(user?.user_type !== 0) ?
                                                        <Link target='_blank' to='https://torbet.org'
                                                            style={{ gap: '5px', display: 'flex', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }} >
                                                            {t('createCourse')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>
                                                        :
                                                        <Link target='_blank' to="https://torbet.org"
                                                            style={{ display: 'flex', gap: '5px', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }}>
                                                            {t('exploreCourses')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>}
                                                </NoContent>}
                                        </InnerBox>

                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <InnerBox>
                                            {coursesAsStudent.length > 0 ?
                                                <>
                                                    {coursesAsStudent.map(course => (
                                                        <CourseCard key={course.course_id} data={course} />
                                                    ))}
                                                    {/* {coursesAsStudent.length >= 4 &&
                                                        <MoreBox onClick={() => handleNavigation()}>
                                                            <Typography variant='h4' sx={{ color: theme.palette.primary.main }}>{t("more")} &nbsp; </Typography>
                                                            {theme.direction === 'ltr' ? (<ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />) : (<ArrowBackIcon sx={{ color: theme.palette.primary.main }} />)}
                                                        </MoreBox>
                                                    } */}

                                                </>
                                                : <NoContent>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/no_courses.png`} style={{ width: '15rem' }} alt={t("noCoursFound")} />
                                                    {(user?.user_type !== 0) ?
                                                        <Link target='_blank' to='https://torbet.org'
                                                            style={{ gap: '5px', display: 'flex', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }} >
                                                            {t('createCourse')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>
                                                        :
                                                        <Link target='_blank' to="https://torbet.org"
                                                            style={{ display: 'flex', gap: '5px', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }}>
                                                            {t('exploreCourses')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>}
                                                </NoContent>}

                                        </InnerBox>

                                    </TabPanel>
                                </SwipeableViews>
                            </> :
                            <InnerBox>
                                {coursesAsStudent.length > 0 ?
                                    <>
                                        {coursesAsStudent.map(course => (
                                            <CourseCard key={course.id} data={course} />
                                        ))}
                                        {/* {coursesAsStudent.length >= 4 &&
                                            <MoreBox onClick={() => handleNavigation()}>
                                                <Typography variant='h4' sx={{ color: theme.palette.primary.main }}>{t("more")} &nbsp; </Typography>
                                                {theme.direction === 'ltr' ? (<ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />) : (<ArrowBackIcon sx={{ color: theme.palette.primary.main }} />)}
                                            </MoreBox>
                                        } */}

                                    </>
                                    : <NoContent>
                                        <img src={`${process.env.PUBLIC_URL}/assets/no_courses.png`} style={{ width: '15rem' }} alt={t("noCoursFound")} />
                                        {(user?.user_type !== 0) ?
                                            <Link target='_blank' to='https://torbet.org'
                                                style={{ gap: '5px', display: 'flex', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }} >
                                                {t('createCourse')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>
                                            :
                                            <Link target='_blank' to="https://torbet.org"
                                                style={{ display: 'flex', gap: '5px', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }}>
                                                {t('exploreCourses')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>}
                                    </NoContent>}

                            </InnerBox>

                        }


                    </Box>
                }
            </ContainerBox>}

        </>
    );


}

export default CourseCards