import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box, styled, useTheme, Typography, Backdrop, Button } from '@mui/material'
import { socket, recordingSocket } from '../../../config/SocketApi';
import Peer from 'simple-peer';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import StudentMeeting from './StudentMeeting';
import { useNavigate } from 'react-router-dom';
import { isMobileBrowser } from '../helpers/helperMethods/isMobileBrowser';
const StudentVideoStream = () => {
    const navigate = useNavigate();
    const [tutorStream, setTutorStream] = useState();

    const [attendees, setAttendees] = useState([])
    const [meetingStarted, setMeetingStarted] = useState(false)
    const [tutorCamEnabled, setTutorCamEnabled] = useState(false)
    const [askedForMic, setAskedForMic] = useState(false)
    const theme = useTheme();
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const { t } = useTranslation();
    const VideoBox = styled(Box)({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        flex: (tutorStream && tutorCamEnabled && !meetingStarted) ? 6 : undefined,
    })
    const ClassRoomCameraBox = styled(Box)({
        display: (tutorStream && tutorCamEnabled && !meetingStarted) ? 'flex' : 'none',
        // flex: (tutorStream && tutorCamEnabled && !meetingStarted) ? 10 : 0,
        border: `1px solid ${theme.palette.text.secondary}`,
        width: isMobileBrowser()?window.innerWidth * 6 / 25 - 6: window.innerWidth * 5 / 25 - 6,
        height:isMobileBrowser()?(window.innerWidth * 6 / 25) / 1.55 - 6:  (window.innerWidth * 5 / 25) / 1.55 - 6,
    })
    const AskedForMicWrapper = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.text.secondary}`,
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: 20
    })
    const ButtonsRow = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
        backgroundColor: theme.palette.background.default,
        gap: 20
    })
    const studentVideoRef = useRef()
    const camerBoxRef = useRef()
    const studentPeerRef = useRef();
    const senderStudentsPeerRefs = useRef([]);
    const studentsPeerRefs = useRef([])
    const attendeesRef = useRef([])
    const serverHandlerPeer = useRef()
    useEffect(() => {
        // socket.emit("student-connected", {})
        socket.on("totur-signal", (data) => {
            if (data.signal.type !== 'offer') {
                studentPeerRef.current.signal(data.signal);
            } else {
                const newPeer = new Peer({
                    initiator: false,
                    trickle: true,
                    config: {
                        iceServers: [{
                            urls: 'stun:5.134.255.233:3478'
                        }
                            ,
                        {
                            urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                            username: 'turnTorbet',
                            credential: 'Torbet@2023'
                        },
                        {
                            urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                            username: 'turnTorbet',
                            credential: 'Torbet@2023'
                        }
                        ]
                    }
                });
                newPeer.signal(data.signal)
                newPeer.on("stream", (stream) => {
                    if (stream.getVideoTracks()[0]) { setTutorCamEnabled(true) } else { setTutorCamEnabled(false) };
                    setTutorStream(stream);
                })

                newPeer.on("signal", (signal) => {
                    socket.emit("student-signal", { signal, tutor_id: data.tutor_id });

                });
                newPeer.on('error', (err) => { console.log("the peer error is ", err) })
                studentPeerRef.current = newPeer;
            }
        })
        socket.on("student-disconnect", (socket_id) => {
            const disconnectdSenderStudent = senderStudentsPeerRefs.current.find(p => p.sender_student_socket_id === socket_id);
            if (disconnectdSenderStudent) {
                disconnectdSenderStudent?.peer.destroy();
                senderStudentsPeerRefs.current = senderStudentsPeerRefs.current.filter(p => p.sender_student_socket_id !== socket_id);
            }
        })

        socket.on("user-disconnected-meeting", (student) => {
            if (student) {
                attendeesRef.current = attendeesRef.current.filter(a => a.socket_id !== student.socket_id)
                setAttendees(attendeesRef.current)
            }

        })

        socket.on("close-mic", (socket_id) => {
            if (studentsPeerRefs.current.length) studentsPeerRefs.current.map(p => p?.peer.destroy());
            studentsPeerRefs.current = [];
            attendeesRef.current.map(s => {
                if (s.socket_id === socket_id) {
                    if (s.stream) {
                        s.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                        s.stream = null;
                    }
                }
            })
            setAttendees(attendeesRef.current)

        })
        socket.on("close-student-mic", (socket_id) => {
            if (senderStudentsPeerRefs.current.length) {
                const disconnectdSenderStudent = senderStudentsPeerRefs.current.find(p => p.sender_student_socket_id === socket_id);
                if (disconnectdSenderStudent) {
                    disconnectdSenderStudent?.peer.destroy();
                    senderStudentsPeerRefs.current = senderStudentsPeerRefs.current.filter(p => p.sender_student_socket_id !== socket_id);
                }
            }
            attendeesRef.current.map(s => {
                if (s.socket_id === socket_id) {
                    if (s.stream) {
                        s.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                        s.stream = null;
                    }
                }
            })
            setAttendees(attendeesRef.current)
        })

        socket.on("student-stream-signal", (data) => {
            if (data.signal.type === 'offer') {
                const newPeer = new Peer({
                    initiator: false,
                    trickle: true,
                    config: {
                        iceServers: [{
                            urls: 'stun:5.134.255.233:3478'
                        }
                            ,
                        {
                            urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                            username: 'turnTorbet',
                            credential: 'Torbet@2023'
                        },
                        {
                            urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                            username: 'turnTorbet',
                            credential: 'Torbet@2023'
                        }
                        ]
                    }
                });
                newPeer.signal(data.signal)
                newPeer.on("stream", (stream) => {
                    attendeesRef.current = attendeesRef.current.map(a => {
                        if (a.socket_id === data.sender_attendee_socket_id) {
                            a.stream = stream
                        }
                        return a;
                    })
                    // const senderAttendeeIndex = attendeesRef.current.findIndex(a => a.socket_id === data.sender_attendee_socket_id);
                    // if (senderAttendeeIndex !== -1) {
                    //     attendeesRef.current = attendeesRef.current.map((attendee, index) => {
                    //         if (index === senderAttendeeIndex) {
                    //             return { ...attendee, stream: stream };
                    //         }
                    //         return attendee;
                    //     });
                    // }
                })
                newPeer.on("signal", (signal) => {
                    socket.emit("student-receiver-stream-signal", { signal, sender_attendee_socket_id: data.sender_attendee_socket_id, sender_server_handler_socket_id: data.sender_server_handler_socket_id, peer_socket_id: socket.id });
                });
                newPeer.on("connect", () => {
                    if (attendeesRef.current) setAttendees(attendeesRef.current)
                })
                newPeer.on('error', (err) => { console.log("the peer error is ", err) })
                const timeStamp = Date.now();
                if (senderStudentsPeerRefs.current.length) {
                    // senderStudentsPeerRefs.current = senderStudentsPeerRefs.current.map(ssp => {
                    //     if (ssp.sender_student_socket_id === data.sender_attendee_socket_id) {
                    //         ssp.peer = newPeer;
                    //         ssp.sender_student_socket_id = data.sender_attendee_socket_id
                    //     }
                    //     return ssp;
                    // })
                    senderStudentsPeerRefs.current = senderStudentsPeerRefs.current.filter(p => p.sender_student_socket_id !== data.sender_attendee_socket_id)
                    senderStudentsPeerRefs.current.push({ peer: newPeer, sender_student_socket_id: data.sender_attendee_socket_id, timeStamp });

                } else {
                    senderStudentsPeerRefs.current.push({ peer: newPeer, sender_student_socket_id: data.sender_attendee_socket_id });
                }
            } else {
                const studentSocketId = data.sender_attendee_socket_id;
                if (senderStudentsPeerRefs.current.length) {
                    const studentPeer = senderStudentsPeerRefs.current.find(p => p.sender_student_socket_id === studentSocketId)
                    if (studentPeer) {
                        studentPeer?.peer.signal(data.signal);
                    }
                }
            }
        })

        socket.on("server-receiver-stream-to-student-signal", (data) => {
            const signal = data.data;
            if (serverHandlerPeer.current) {
                serverHandlerPeer.current.signal(signal);
            }
        })
        recordingSocket.on("end-session-student", () => {
            if (studentsPeerRefs.current) studentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
            if (senderStudentsPeerRefs.current) senderStudentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
            if (serverHandlerPeer.current) { serverHandlerPeer.current.destroy(); serverHandlerPeer.current = null }

            attendeesRef.current.map(a => {
                if (a.user_id === user.id && a.stream && a.user_type === 0) {
                    a.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    a.stream = false;
                }
            })
            studentsPeerRefs.current = []
            senderStudentsPeerRefs.current = [];
            attendeesRef.current = []
            navigate("/dashboard")
        })
        socket.on("stop-video-recording-received", () => {
            if (studentPeerRef?.current !== null) {
                studentPeerRef.current.destroy();
                studentPeerRef.current = null;
            }
            studentVideoRef.current = null;
            setTutorStream(null)
        })

        socket.on("open-mic", () => {
            setAskedForMic(true)

        })
        socket.on("tutor-cam-enabled", (condition) => {
            setTutorCamEnabled(condition)
        })
        socket.on("student-connected-meeting", student => {
            const foundUser = attendeesRef.current.find(a => a.user_id === student.user_id)
            if (foundUser) {
                attendeesRef.current = [...attendeesRef.current.filter(a => a.user_id !== student.user_id), student]
            } else {
                attendeesRef.current = [...attendeesRef.current, student]
            }
            setAttendees(attendeesRef.current)
        })
        socket.on("meeting-state-sent", (data) => {
            const dataParsed = JSON.parse(data);
            setMeetingStarted(dataParsed.state)
        })
        socket.on("meeting-end-session", () => {
            if (studentsPeerRefs.current) studentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
            if (senderStudentsPeerRefs.current) senderStudentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
            attendeesRef.current.map(a => {
                if (a.user_id === user.id && a.stream && a.user_type === 0) {
                    a.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
            })
            attendeesRef.current = []
        })
        socket.on("connect_error", error => console.log(error))

        return () => {
            recordingSocket.off("end-session-student")
            socket.off("meeting-state-sent");
            socket.off("meeting-end-session");
            socket.off("student-connected-meeting");
            socket.off("user-disconnected-meeting");
            socket.off("tutor-cam-enabled");
            socket.off("connect_error");
            socket.off("open-mic");
            socket.off("close-mic");
            socket.off("close-student-mic");
            socket.off("student-stream-signal")
            socket.off("totur-signal");
            socket.off("student-disconnect");
            socket.off('stop-video-recording-received')
            socket.off('start-recording-received')
            if (studentPeerRef?.current) {
                studentPeerRef.current.destroy();
                studentPeerRef.current = null;
            }
            studentVideoRef.current = null;
            if (tutorStream) {
                tutorStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (studentsPeerRefs.current) studentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
            if (senderStudentsPeerRefs.current) senderStudentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
            if (serverHandlerPeer.current) { serverHandlerPeer.current.destroy(); serverHandlerPeer.current = null }

            attendeesRef.current.map(a => {
                if (a.user_id === user.id && a.stream && a.user_type === 0) {
                    a.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
            })
            studentsPeerRefs.current = []
            senderStudentsPeerRefs.current = [];
            attendeesRef.current = []
            setTutorStream(null)
        }

    }, [])

    const createServerHandlerPeer = (serverHandler, stream, attendee) => {

        const newPeer = new Peer({
            initiator: true,
            trickle: true,
            stream,
            config: {
                iceServers: [{
                    urls: 'stun:5.134.255.233:3478'
                },
                {
                    urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                },
                {
                    urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                }]
            }
        });
        newPeer.on("signal", (data) => {
            socket.emit("student-stream-signal-to-server-handler", { signal: data, handlerSocketId: serverHandler.socket_id, peerSocketId: socket.id, attendee });
        });
        newPeer.on('error', (err) => {
            console.log("the peer error is ", err)

        })
        if (serverHandler.current) serverHandler.current.destroy();
        serverHandlerPeer.current = newPeer
    }
    useEffect(() => {
        if (attendees.length && !meetingStarted) {
            attendees.map(s_a => {
                if (s_a.user_id !== user.id) {
                    const socket_id = s_a.socket_id;
                    const foundAudioEl = document.getElementById(socket_id);
                    if (foundAudioEl) {
                        foundAudioEl.srcObject = s_a.stream
                    } else {
                        var audioEl = document.createElement('audio');
                        audioEl.setAttribute("id", s_a.socket_id)
                        audioEl.controls = false;
                        audioEl.autoplay = true;
                        audioEl.srcObject = s_a.stream;
                        document.body.appendChild(audioEl)
                    }
                }
            })
        }
    }, [attendees, meetingStarted])

    useEffect(() => {
        if (studentVideoRef.current && tutorStream) {
            studentVideoRef.current.srcObject = tutorStream;
        }
    }, [tutorStream, askedForMic, tutorCamEnabled, meetingStarted, attendees])

    useEffect(() => {
        if (meetingStarted && attendees.length && user) {
            console.log("the user reading to receive stream", user.id)
            socket.emit("user-ready-to-receive-stream", user.id)
        }
        studentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
        senderStudentsPeerRefs.current.map(p => { if (p.peer) p.peer.destroy() });
        attendeesRef.current.map(a => {
            if (a.user_id === user.id && a.stream && a.user_type === 0) {
                a.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                a.stream = null;
                socket.emit("close-student-mic", a.user_id)
            }
        })
        attendeesRef.current = []
        socket.emit("get-connected-students-include-sender", students => {
            if (students.length) {
                attendeesRef.current = students//[...attendeesRef.current.filter(s => s.socket_id !== senderAttendee.socket_id), senderAttendee]

                setAttendees(students)

            }
        })
    }, [meetingStarted, user])
    const handleStreamStudentAudio = (attendee) => {
        if (!attendee) {
            attendee = attendeesRef.current.find(a => a.user_id === user.id)
            socket.emit("student-mic-connected", { student_socket_id: attendee.socket_id })
        }
        if (attendee.stream) {
            if (attendee.camEnabled) {
                attendeesRef.current = attendeesRef.current.map(a => {
                    if (a.user_id === attendee.user_id) {
                        a.stream.getAudioTracks()[0].enabled = !attendee.micEnabled;
                        a.micEnabled = !attendee.micEnabled
                    }
                    return a;
                })
                // attendee.stream.getAudioTracks()[0].enabled = !attendee.micEnabled;
                // attendee.micEnabled = !attendee.micEnabled
                // attendeesRef.current = [...attendeesRef.current.filter(s => s.socket_id !== attendee.socket_id), attendee]
                setAttendees(attendeesRef.current)
            } else {
                // attendee.micEnabled = false;
                attendee.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                socket.emit("kill-attendee-stream", attendee)
                // attendee.stream = false
                attendeesRef.current = attendeesRef.current.map(a => {
                    if (a.user_id === attendee.user_id) {
                        a.stream = false;
                        a.micEnabled = false
                    }
                    return a;
                })
                if (studentsPeerRefs.current.length) studentsPeerRefs.current.map(p => p?.peer.destroy());
                studentsPeerRefs.current = [];
                // attendeesRef.current = [...attendeesRef.current.filter(s => s.socket_id !== attendee.socket_id), attendee]
                setAttendees(attendeesRef.current)
            }

        } else {
            const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
            const constraints = {
                video: false, // Fallback to true if any of the video constraints are not supported
                audio: 
                // supportedConstraints.sampleRate && supportedConstraints.sampleSize && supportedConstraints.channelCount ? {
                //     sampleRate: 22050,
                //     sampleSize: 16,
                //     channelCount: 1,
                // } : 
                true // Fallback to true if any of the audio constraints are not supported
            };
            navigator.mediaDevices.getUserMedia(constraints).then(str => {
                // attendee.stream = str;
                // attendee.micEnabled = true;
                // attendee.camEnabled = false;
                attendeesRef.current = attendeesRef.current.map(a => {
                    if (a.user_id === attendee.user_id) {
                        attendee.stream = str;
                        a.micEnabled = true;
                        a.camEnabled = false;
                    }
                    return a;
                })
                // attendeesRef.current = [...attendeesRef.current.filter(s => s.socket_id !== attendee.socket_id), attendee]
                setAttendees(attendeesRef.current)
                handleStreamToServer(attendee, str)

            }).catch(error => console.log(error))
        }
    }
    const handleStreamStudentCam = (attendee) => {
        if (attendee.stream) {
            if (attendee.camEnabled) {
                if (studentsPeerRefs.current.length) studentsPeerRefs.current.map(p => p?.peer.destroy());
                studentsPeerRefs.current = [];
                attendee.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
                const constraints = {
                    video: false, // Fallback to true if any of the video constraints are not supported
                    audio: 
                    // supportedConstraints.sampleRate && supportedConstraints.sampleSize && supportedConstraints.channelCount ? {
                    //     sampleRate: 22050,
                    //     sampleSize: 16,
                    //     channelCount: 1,
                    // } : 
                    true // Fallback to true if any of the audio constraints are not supported
                };
                navigator.mediaDevices.getUserMedia(constraints).then(str => {
                    // attendee.stream = str;
                    // attendee.camEnabled = false;
                    // attendee.micEnabled = true;
                    attendeesRef.current = attendeesRef.current.map(a => {
                        if (a.user_id === attendee.user_id) {
                            attendee.stream = str;
                            a.micEnabled = true;
                            a.camEnabled = false;
                        }
                        return a;
                    })
                    // attendeesRef.current = [...attendeesRef.current.filter(s => s.socket_id !== attendee.socket_id), attendee]
                    setAttendees(attendeesRef.current)
                    handleStreamToServer(attendee, str)
                }).catch(error => console.log(error))

            } else {
                if (studentsPeerRefs.current.length) studentsPeerRefs.current.map(p => p?.peer.destroy());
                studentsPeerRefs.current = [];
                if (attendee.stream) {
                    attendee.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
                const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
                const constraints = {
                    video: supportedConstraints.width && supportedConstraints.frameRate && supportedConstraints.aspectRatio ? {
                        width: { max: 360 },
                        frameRate: { max: 10 },
                        aspectRatio: { exact: 1.55 }
                    } : true, // Fallback to true if any of the video constraints are not supported
                    audio: 
                    // supportedConstraints.sampleRate && supportedConstraints.sampleSize && supportedConstraints.channelCount ? {
                    //     sampleRate: 22050,
                    //     sampleSize: 16,
                    //     channelCount: 1,
                    // } : 
                    true // Fallback to true if any of the audio constraints are not supported
                };
                navigator.mediaDevices.getUserMedia(constraints).then(str => {
                    attendee.stream = str;
                    attendee.camEnabled = true;
                    attendee.micEnabled = true;
                    attendeesRef.current = attendeesRef.current.map(a => {
                        if (a.user_id === attendee.user_id) {
                            attendee.stream = str;
                            a.micEnabled = true;
                            a.camEnabled = true;
                        }
                        return a;
                    })
                    // attendeesRef.current = [...attendeesRef.current.filter(s => s.socket_id !== attendee.socket_id), attendee]
                    setAttendees(attendeesRef.current)
                    handleStreamToServer(attendee, str)
                }).catch(error => console.log(error))
            }

        } else {
            const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
            const constraints = {
                video: supportedConstraints.width && supportedConstraints.frameRate && supportedConstraints.aspectRatio ? {
                    width: { max: 360 },
                    frameRate: { max: 10 },
                    aspectRatio: { exact: 1.55 }
                } : true, // Fallback to true if any of the video constraints are not supported
                audio: 
                // supportedConstraints.sampleRate && supportedConstraints.sampleSize && supportedConstraints.channelCount ? {
                //     sampleRate: 22050,
                //     sampleSize: 16,
                //     channelCount: 1,
                // } : 
                true // Fallback to true if any of the audio constraints are not supported
            };
            navigator.mediaDevices.getUserMedia(constraints).then(str => {
                // attendee.stream = str;
                // attendee.camEnabled = true;
                // attendee.micEnabled = true;
                attendeesRef.current = attendeesRef.current.map(a => {
                    if (a.user_id === attendee.user_id) {
                        a.stream = str;
                        a.micEnabled = true;
                        a.camEnabled = true;
                    }
                    console.log("a after modifieng", a)
                    return a;
                })
                // attendeesRef.current = [...attendeesRef.current.filter(s => s.socket_id !== attendee.socket_id), attendee]
                setAttendees(attendeesRef.current)
                handleStreamToServer(attendee, str)


            }).catch(error => console.log(error))
        }
    }
    const handleStreamToServer = useCallback((attendee, str) => {
        socket.emit("get-server-handler", serverHandler => {
            if (serverHandler) {
                createServerHandlerPeer(serverHandler, str, attendee);
            }
        })
    }, [attendees])

    return (
        <VideoBox>
            <StudentMeeting attendees={attendees} tutorStream={tutorStream} meetingStarted={meetingStarted} handleStreamStudentAudio={(attendee) => handleStreamStudentAudio(attendee)} handleStreamStudentCam={(attendee) => handleStreamStudentCam(attendee)} />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={askedForMic}
            >
                <AskedForMicWrapper>
                    <Typography variant='h4'>{t('ask_for_mic')}</Typography>
                    <ButtonsRow>
                        <Button onClick={() => { socket.emit('student-aplogized-mic', user.id); setAskedForMic(false); }} variant="contained" sx={{ backgroundColor: theme.palette.danger.main }}>{t("apologize")}</Button>
                        <Button onClick={() => { handleStreamStudentAudio(null); setAskedForMic(false) }} variant="contained">{t("accept")}</Button>
                    </ButtonsRow>
                </AskedForMicWrapper>

            </Backdrop>
            <ClassRoomCameraBox ref={camerBoxRef} >
                <video id='live_stream_video' ref={studentVideoRef} style={{ width:isMobileBrowser()?(window.innerWidth * 6 / 25 - 8): (window.innerWidth * 5 / 25 - 8), display: (tutorStream && tutorCamEnabled && !meetingStarted) ? 'inline-block' : 'none', }} autoPlay playsInline />
            </ClassRoomCameraBox>
        </VideoBox>
    )
}


export default StudentVideoStream