import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Tooltip, Badge, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Close from '@mui/icons-material/Close';
import { useAuthContext } from '../auth/hooks/useAuthContext';

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 50,
    right: 0,
    maxHeight: 300,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: '10px 10px 0 0',
    overflow: 'auto',
    backgroundColor: 'white',
    display: 'block',
    width: window.innerWidth * 5 / 26 + "px", // Adjust width as needed
    paddingTop: 40,
    zIndex: 1000,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.danger.main,
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
    marginTop: 2,
    '& :hover': {
        backgroundColor: theme.palette.danger.main,
        // width: '30px',
        // height: '30px',
        // padding: '5px',
        borderRadius: '100%',
        // border: `15px solid ${theme.palette.danger.main}`
    }
}));

const ToggleButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.secondary.main,
    zIndex: 1000
}));

function ActionCenter() {
    const [events, setEvents] = useState([{ image: "", text: "test the action center", seen: false, id: 1 }, { image: "", text: "test the action center", seen: false, id: 2 }, { image: "", text: "test the action center", seen: false, id: 1 }, { image: "", text: "test the action center", seen: false, id: 2 }, { image: "", text: "test the action center", seen: false, id: 1 }, { image: "", text: "test the action center", seen: false, id: 2 }]); // Assuming each event has an 'id', 'seen', 'type', 'image', and 'text'
    const [show, setShow] = useState(false);
    const [unseenCount, setUnseenCount] = useState(0);
    const theme = useTheme()
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        // Assuming initially all events are unseen
        if (events.length === 0) setShow(false)
        setUnseenCount(events.filter(event => !event.seen).length);
    }, [events]);

    const handleCheck = (eventId) => {
        // Check logic based on event type
    };

    const handleClose = (eventId) => {
        setEvents(events.filter(e => e.id !== eventId));
    };

    const toggleShow = () => {
        setShow(!show);
        if (!show) {
            // Mark all events as seen when opening the action center
            const updatedEvents = events.map(event => ({ ...event, seen: true }));
            setEvents(updatedEvents);
        }
    };
    return (
        <>
            <StyledBox id="action-center" sx={{ display: show ? 'block' : 'none' }}>
                <Tooltip title="Hide Action Center">
                    <CloseButton onClick={() => setShow(false)} color="inherit">
                        <CloseIcon />
                    </CloseButton>
                </Tooltip>
                <List>
                    {events.map((event, index) => (
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar src={event.image} />
                            </ListItemAvatar>
                            <ListItemText primary={event.text} sx={{ color: "black" }} />
                            <IconButton edge="end" aria-label="check" onClick={() => handleCheck(event.id)} sx={{ color: 'black' }}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="close" onClick={() => handleClose(event.id)} sx={{ color: 'black' }}>
                                <CloseIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </StyledBox>
            {user &&
                <>
                    {!show && user.user_type !== 0 && (
                        <Tooltip title="Open Action Center">
                            <ToggleButton onClick={toggleShow} sx={{ position: 'absolute', bottom: 10, right: theme.direction === 'ltr' ? 3 : 'unset', left: theme.direction === 'rtl' ? 3 : 'unset' }}>
                                <Badge badgeContent={unseenCount} color="secondary" >
                                    <NotificationsIcon />
                                </Badge>
                            </ToggleButton>
                        </Tooltip>
                    )}
                </>}

        </>
    );
}

export default ActionCenter;
