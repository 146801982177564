
import { createContext, useState, useMemo, useEffect } from "react";
import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles";

import './font.css'
import { useTranslation } from "react-i18next";
// color design tokens export
export const tokens = (mode) => ({
    ...(mode === "dark"
        ? {
            secondary: {
                900: "#FFF2C6",
                800: "#FFE487",
                700: "#FFCF49",
                600: "#FFBA1F",
                500: "#FB9A0B",
                400: "#DE7001",
                300: "#B84C05",
                200: "#953B0B",
                100: "#7B300C",
            }, text: {
                default: "#FFFFFF",
                secondary: "#BDBDBD"
            },

            background: {
                default: "#333333",
                redAccent: alpha("#ED9D99", 0.25),
                gray: "#4F4F4F"
            },
            icon: {
                main: "#ED9D99",
            },
            primary: {
                900: "#E6EDF8",
                800: "#C7DAF0",
                700: "#96BAE3",
                600: "#5D97D3",
                500: "#3B7EC5",
                400: "#285FA1",
                300: "#224C82",
                200: "#1F426D",
                100: "#1F395B",
            },
            success: {
                900: "#dbf5ee",
                800: "#BEF4D4",
                700: "#8BEAB2",
                600: "#52D689",
                500: "#27AE60",
                400: "#1D9C53",
                300: "#1A7B43",
                200: "#1A6139",
                100: "#175031",
            },
            danger: {
                900: "#FDE3E3",
                800: "#FCCCCC",
                700: "#F9A8A8",
                600: "#F47575",
                500: "#EB5757",
                400: "#D62C2C",
                300: "#B42121",
                200: "#951F1F",
                100: "#7C2020",
            },
            secondary2: {
                900: "#FBEBFC",
                800: "#F6D7F7",
                700: "#F0B7F0",
                600: "#E68CE4",
                500: "#D14DCD",
                400: "#B940B3",
                300: "#993291",
                200: "#7D2B76",
                100: "#672860",
            },
        }
        : {
            secondary: {
                100: "#FFF2C6",
                200: "#FFE487",
                300: "#FFCF49",
                400: "#FFBA1F",
                500: "#FB9A0B",
                600: "#DE7001",
                700: "#B84C05",
                800: "#953B0B",
                900: "#7B300C",
            }
            , text: {
                default: "#333333",
                secondary: "#828282"
            },
            icon: {
                main: "#ED9D99",
            },
            background: {
                default: "#FFFFFF",
                redAccent: "#ED9D99",// used with alpha
                gray: "#F2F2F2"
            },
            primary: {
                100: "#E6EDF8",
                200: "#C7DAF0",
                300: "#96BAE3",
                400: "#5D97D3",
                500: "#3B7EC5",
                600: "#285FA1",
                700: "#224C82",
                800: "#1F426D",
                900: "#1F395B",
            },
            success: {
                100: "#dbf5ee",
                200: "#BEF4D4",
                300: "#8BEAB2",
                400: "#52D689",
                500: "#27AE60",
                600: "#1D9C53",
                700: "#1A7B43",
                800: "#1A6139",
                900: "#175031",
            },
            danger: {
                100: "#FDE3E3",
                200: "#FCCCCC",
                300: "#F9A8A8",
                400: "#F47575",
                500: "#EB5757",
                600: "#D62C2C",
                700: "#B42121",
                800: "#951F1F",
                900: "#7C2020",
            },
            secondary2: {
                100: "#FBEBFC",
                200: "#F6D7F7",
                300: "#F0B7F0",
                400: "#E68CE4",
                500: "#D14DCD",
                600: "#B940B3",
                700: "#993291",
                800: "#7D2B76",
                900: "#672860",
            },
        }),
});

// mui theme settings
export const themeSettings = (mode, lang) => {
    const colors = tokens(mode);
    return {
        direction: lang === 'ar' ? 'rtl' : 'ltr',
        palette: {
            mode,
            primary: {
                main: colors.primary[500],
            },
            secondary: {
                main: colors.secondary[500],
                other: colors.secondary2[500],
            },
            danger: {
                main: colors.danger[500],
            },
            success: {
                main: colors.success[500],
            },
            background: {
                default: colors.background.default,
                redAccent: colors.background.redAccent,
                gray: colors.background.gray
            },
            text: {
                default: colors.text.default,
                secondary: colors.text.secondary
            },
            icon: {
                main: colors.icon.main
            }

        },
        typography: {
            fontSize: 12,
            fontFamily: lang === "en" ? [
                'Poppins',
                'sans-serif'
            ].join(',') : [
                'Almarai',
                'sans-serif'
            ].join(','),
            h1: {
                fontWeight: lang === "en" ? '700' : "700",
                fontSize: 40,
            },
            h2: {
                fontWeight: lang === "en" ? '600' : "400",
                fontSize: 32,
            },
            h3: {
                fontWeight: lang === "en" ? '500' : "400",
                fontSize: 24,
            },
            h4: {
                fontWeight: lang === "en" ? '400' : "300",
                fontSize: 20,
            },
            h5: {
                fontWeight: "300",
                fontSize: 16,
            },
            h6: {
                fontWeight: lang === "en" ? '200' : "300",
                fontSize: 14,
            },
        },
    };
};

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => { },
});
export const LangContext = createContext({
    toggleLanguage: () => { },
});

export const useMode = () => {
    const { i18n } = useTranslation();
    const newLang = JSON.parse(localStorage.getItem('lang'));
    const newMode = JSON.parse(localStorage.getItem('mode'));
    const [mode, setMode] = useState(newMode ? newMode.mode : "light");
    const [lang, setLang] = useState(newLang ? newLang.lang : "ar");
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prev) => {
                    const newMode = prev === "light" ? "dark" : "light";
                    localStorage.setItem('mode', JSON.stringify({ mode: newMode ?? 'light' }))
                    return newMode;
                })
            }
        }),
        []
    );
    const language = useMemo(
        () => ({
            toggleLanguage: () => {
                setLang(prev => {
                    const newLang = prev === "en" ? "ar" : "en";
                    //localStorage.setItem('lang', JSON.stringify({ lang: newLang ?? 'en' }))
                    i18n.changeLanguage(newLang);
                    return newLang;
                })
            }
        }),
        []
    );
    useEffect(() => {
        const prevLang = localStorage.getItem('lang')
        if (prevLang) localStorage.removeItem('lang')
        localStorage.setItem('lang', JSON.stringify({ lang: lang }))
    }, [lang])
    const theme = useMemo(() => responsiveFontSizes(createTheme(themeSettings(mode, lang))), [mode, lang]);
    return [theme, colorMode, language];
};
