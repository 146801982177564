import React, { useContext } from 'react';
import { Box, Breadcrumbs, Typography, useTheme, styled } from '@mui/material'
import { BreadCrumbContext } from './../context/BreadCrumbContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
function BreadCrumb() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const { breadCrumbState, breadCrumbSetter } = useContext(BreadCrumbContext)
    const TypographyLink = styled(Typography)({
        cursor: "pointer",
        '&:hover': {
            textDecoration: "underline"
        }
    })

    const handleBreadClick = (id, route) => {
        breadCrumbSetter(id);
        navigate(route)
    }
    return (

        <Box sx={{ flex: 1, display: 'flex', justifyItems: 'center', paddingTop: '0.5rem', alignItems: 'center' }}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadCrumbState.map(bc => {
                    if (bc.id < breadCrumbState.length) {
                        return (<TypographyLink onClick={() => handleBreadClick(bc.id, bc.route)} >{bc.translatable ? t(bc.title) : bc.title}</TypographyLink>)
                    } else {
                        return (<Typography >{bc.translatable ? t(bc.title) : bc.title}</Typography>)
                    }
                })}
            </Breadcrumbs>
        </Box>


    )
}

export default BreadCrumb