import React, { useCallback, useEffect, useState } from 'react'
import { Backdrop, Box, Button, CardMedia, IconButton, Link, ScopedCssBaseline, Typography, styled, useTheme } from '@mui/material'
import MasterAppBar from '../master/components/MasterAppBar'
import { useAuthContext } from '../auth/hooks/useAuthContext';
import StudentBoard from './components/StudentBoard';
import ChatArea from './components/ChatArea';
import CloseIcon from '@mui/icons-material/Close';
import { socket } from '../../config/SocketApi';
import { recordingSocket } from '../../config/SocketApi';
import { useParams } from 'react-router-dom'
import StudentVideoStream from './components/StudentVideoStream';
import { useNavigate } from 'react-router-dom';
import { reset } from '../../features/tabs/tabsSlice';
import { toggleMeetingState } from '../../features/meeting/meetingSlice';
import { useDispatch } from 'react-redux';
import ActionCenter from '../shared/ActionCenter';
import API from '../../config/API';
import { useTranslation } from 'react-i18next';
import { isMobileBrowser } from './helpers/helperMethods/isMobileBrowser';
const WhitBoard = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const {t} = useTranslation()
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const { s_id } = useParams();
    const [courseTeacherId, setCourseTeacherId] = useState()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const WhiteBoardContainer = styled(Box)({
        // height: '100vh',
        // width: '100vw',
        flexDirection: 'column'
    })
    const ClassRoomBox = styled(Box)({
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    })
    const RightBox = styled(Box)({
        display: 'flex',
        flex: isMobileBrowser()?19 :20,
        flexDirection: 'column',
        position: 'relative'
    })
    const LeftBox = styled(Box)({
        display: 'flex',
        flex: isMobileBrowser()?6 :5,
        flexDirection: 'column',

    })

    const handleEndSession = () => {
        socket.emit("end-browser-page", {})
        navigate('/');
    }
    const checkUserAccess = useCallback(()=>{
        if(user && s_id){
            API.get(`check_user_access_class_room?sess_id=${s_id}`, {
                headers: {
                    Authorization: user.token,
                    "Content-Type": 'application/json'
                }
            }).then(data => {
                console.log("the data is ", data)
                if (data.status === 200) {
                    if(data?.data?.is_teacher){
                        setCourseTeacherId(true)
                    }
                    setIsLoading(false);
                    
                } else {
                    setIsLoading(false)
                    navigate("/")
                    // throw Error('Someting wrong happened')
                }
                // console.log("the course data is ", data)
                setIsLoading(false)
            }).catch(err => { console.log("the error is ", err); setIsLoading(false); navigate("/") })
        }else{
            navigate("/")
        }
    },[user, s_id])

    useEffect(() => {
        if (!user) {
            return;
         }
        checkUserAccess()

    }, [user, s_id, checkUserAccess])

    useEffect(()=>{
        if(!courseTeacherId && !isLoading && user && s_id){
            socket.connect().auth = { token: user?.token, room_id: s_id, user_type: 0, user_id: user?.id, user_name: user?.full_name, user_image: user?.img_path }
            recordingSocket.connect().auth = { token: user?.token, room_id: s_id, user_type: 0, user_id: user?.id, user_name: user?.full_name, user_image: user?.img_path }
            // recordingSocket.on("end-session", () => {
            //     if (user?.user_type !== 0) handleEndSession() //: navigate("/dashboard")
            // })
        }
        return () => {
            dispatch(reset({}))
            dispatch(toggleMeetingState({ status: false }))
            // recordingSocket.off("end-session");
            if (socket.connected) socket.disconnect();
            if (recordingSocket.connected) recordingSocket.disconnect()
        }
    },[courseTeacherId, isLoading, user, s_id])
    const ButtonsRow = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
        backgroundColor: theme.palette.background.default,
        gap: 20
    })
    const AskedForMicWrapper = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.text.secondary}`,
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: 20
    })
    return (
        <ScopedCssBaseline>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CardMedia component="img"
                    image="./assets/loading.gif"
                    sx={{ width: '8rem' }}
                />
            </Backdrop>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={courseTeacherId}
            >
                <AskedForMicWrapper>
                    <Typography variant='h5'>{t('course_teach_web')}</Typography>
                    <ButtonsRow>
                        <Button onClick={() => { navigate("/") }} variant="contained">{t("accept")}</Button>
                        <Link href="https://torbet.org" target="_blank" rel="noopener noreferrer">
                            <Button variant="contained">{t("go_to_website")}</Button>
                        </Link>
                        
                    </ButtonsRow>
                </AskedForMicWrapper>

            </Backdrop>
            {/* <ActionCenter /> */}
            {user && !isLoading && !courseTeacherId &&
            <WhiteBoardContainer>
                <MasterAppBar crumb={false} virtualRoom={true} />
                <ClassRoomBox>
                    <RightBox>
                        <Box ><StudentBoard /></Box>
                    </RightBox>
                    <LeftBox>
                         <StudentVideoStream />
                        {s_id && <ChatArea room_id={s_id} />}
                    </LeftBox>
                </ClassRoomBox>
                {/* {(user?.user_type !== 0 && parseInt(tu_id) === user.id) && <ButtomBar room_id={s_id} />} */}
            </WhiteBoardContainer>}
        </ScopedCssBaseline>
    )
}

export default WhitBoard