import { Box, styled, useTheme } from '@mui/material';
import React, { useState } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { tokens } from '../../../layout/theme';
function MainCalendar() {
    const [value, onChange] = useState(new Date());
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const CalendarContainer = styled(Box)(({ theme }) => ({
        marginTop: '3.5rem',
        marginBottom:'3.5rem',
        '& .react-calendar': {
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.gray : theme.palette.background.default,
            border: `1px solid ${theme.palette.text.gray}`,
            borderRadius: ' 10px',
            width: '550px',
            color: theme.palette.text.default
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            '& .react-calendar': {
                width: '400px',
            }
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            '& .react-calendar': {
                width: '450px',
            }
        },
        '& .react-calendar': {
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.gray : theme.palette.background.default,
            border: `1px solid ${theme.palette.text.gray}`,
            borderRadius: ' 10px',
            width: '550px',
            color: theme.palette.text.default
        },
        '& .react-calendar button:not(.react-calendar__month-view__days__day--weekend, .react-calendar__month-view__days__day--neighboringMonth)': {
            color: theme.palette.text.default
        },
        '& .react-calendar .react-calendar__month-view__days': {
            height: '320px'
        },
        '& .react-calendar .react-calendar__tile--active': {
            background: 'none',
            color: theme.palette.background.gray,
        },
        '& .react-calendar__tile:hover': {
            backgroundColor: colors.secondary[300],
        },
        '& .react-calendar .react-calendar__tile--active abbr': {
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${theme.palette.text.default}`,
            borderRadius: ' 50%',
            width: '30px',
            height: '30px'
        },
        '& .react-calendar .react-calendar__navigation button': {
            color: theme.palette.text.default
        },
        '& .react-calendar .react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth)': {
            color: theme.palette.text.default
        }, '& .react-calendar .react-calendar__month-view__days__day--neighboringMonth': {
            color: theme.palette.text.gray
        },
        '& .react-calendar .react-calendar__tile--now': {
            backgroundColor: theme.palette.success.main
        },


    }))
    return (
        <CalendarContainer sx={{ flex: 1 }}>
            <Calendar className="react-calendar-CalendarContainer" onChange={onChange} value={value} />
        </CalendarContainer>
    )
}

export default MainCalendar