import React, { useRef, useState, memo } from 'react'
import Box from '@mui/material/Box'
import { IconButton, InputBase, styled, useTheme } from '@mui/material'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import BackHandIcon from '@mui/icons-material/BackHand';
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next'
import { socket } from '../../../config/SocketApi';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import Chip from '@mui/material/Chip';
const ChatInput = ({ onAddComment, onChangeLastMessage, lastMessage = '', privateMessage, onPrivateMessageSent })=> {
    const theme = useTheme();
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
    const { t } = useTranslation();
    const ChatBox = styled(Box)({
        padding: '10px 10px',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        border: `1px solid ${theme.palette.background.gray}`,
        backgroundColor: theme.palette.background.gray,
        borderRadius: '8px',
        maxWidth: '100%',
        wordBreak: 'normal',
        lineBreak: 'auto',
        position: 'relative'
    })
    const InputBox = styled(InputBase)({
        flex: 8,
        color: theme.palette.text.default,
        backgroundColor: theme.palette.background.gray,
        outline: 'none',
        border: 'none',
        wordBreak: 'normal',
        lineBreak: 'auto'
    })
    const ButtonBox = styled(Box)({
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        justifyItems: 'flex-start',
        alignItems: 'center',
        width: '30%'
    })
    const handleSendMessage = () => {
        if (lastMessage !== "") {
            var date = new Date();
            const timeSent = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
            if (privateMessage) {
                console.log("privat messtge sent ", lastMessage, user.name, user.user_type, timeSent, privateMessage.u_id, user.full_name, user.img_path)
                socket.emit("private-message", lastMessage, user.name, user.user_type, timeSent, privateMessage.u_id, user.full_name, user.img_path);
                onPrivateMessageSent()
            } else {
                socket.emit("public-message", lastMessage, user.name, user.user_type, timeSent, user.full_name, user.img_path);
            }
            onAddComment(lastMessage, user.name, user.user_type, timeSent, user.full_name, user.img_path);
            onChangeLastMessage("")
        }
    }
    const handleKeypress = e => {
        if (e.code === 'Enter') {
            handleSendMessage();
        }
    };
    const inputRef = useRef();
    const handleRaiseHand = () => {

    }
    return (
        <ChatBox>
            {privateMessage && <Chip sx={{ position: 'absolute', zIndex: 1000, top: '-25px', right: 0, paddingLeft: '10px' }} label={t('private_message_to') + ' ' + privateMessage.user_name} variant='filled' color='warning' onDelete={() => onPrivateMessageSent()} />}
            <InputBox onKeyDown={(e) => handleKeypress(e)} dir={theme.direction} ref={inputRef} placeholder={t("sendmessage")} autoFocus value={lastMessage} onChange={(e) => { onChangeLastMessage(e.target.value) }}></InputBox>
            {emojiPickerOpen && <Box sx={{ position: 'absolute', top: "-300px", right: "0px", zIndex: 1000 }}>
                <EmojiPicker
                    width="100%"
                    height={300}
                    searchDisabled={true}
                    lazyLoadEmojis={true}
                    onEmojiClick={(e) => onChangeLastMessage(prev => prev + e.emoji)}
                    emojiStyle={EmojiStyle.NATIVE}
                >
                </EmojiPicker>
            </Box>}
            {user.user_type === 0 ? <ButtonBox>

                <IconButton sx={{ margin: 0, padding: 0.3 }} onClick={() => setEmojiPickerOpen(prev => !prev)}><EmojiEmotionsIcon sx={{ width: 15, margin: 0 }} /></IconButton>
                <IconButton sx={{ margin: 0, padding: 0.3 }} onClick={() => handleRaiseHand()}><BackHandIcon sx={{ width: 15, margin: 0 }} /></IconButton>
                <IconButton sx={{ margin: 0, padding: 0.3 }} onClick={() => handleSendMessage()}>
                    <SendIcon sx={{ width: 15, margin: 0 }} />
                </IconButton>
            </ButtonBox> :
                <>
                    <IconButton onClick={() => setEmojiPickerOpen(prev => !prev)}><EmojiEmotionsIcon /></IconButton>
                    <IconButton onClick={() => handleSendMessage()}>
                        <SendIcon />
                    </IconButton>
                </>
            }
        </ChatBox>
    )
}

export default ChatInput