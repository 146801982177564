import React, { useState, useEffect,useRef, useCallback } from 'react'
import { Backdrop, Box, Button, IconButton, Link, styled, Typography, useTheme } from '@mui/material'
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { socket } from '../../../config/SocketApi'
import MailLockIcon from '@mui/icons-material/MailLock';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import Avatar from '@mui/material/Avatar';
import { BaseUrl } from '../../../config/API';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobileBrowser } from '../helpers/helperMethods/isMobileBrowser';
import Peer from 'simple-peer';
import { getInitialForAvatar } from '../helpers/helperMethods/getInitials';
const AttendeeBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 24px 4px 24px',
    width: '100%',
})
const AttendeeInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px'
})

const AttendeeStatusBox = styled(Box)({
    width: '10px',
    height: '10px',
    border: '1px solid green',
    borderRadius: '50%',
    backgroundColor: 'green'
})
const AttendeeControlls = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px'
})

const Attendee = ({ data, onaddPrivateMessage, room_id }) => {
    console.log("the room id ", room_id)
    const theme = useTheme();
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const { meetingStarted } = useSelector((store) => store.meeting)
    const [screenSources, setScreenSources] = useState(false)
    const [studentStream,setStudentStream] = useState(false)
    const [error,setError] = useState()
    const [downloadApp, setDownloadApp] = useState(false)
    const [tutorStream,setTutorStream] = useState();
    const { t } = useTranslation();
    const [micSent, setMicSent] = useState(false)
    const teacherPeerRef = useRef() 
       // const { room_id } = useParams();
    const AskedForMicWrapper = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.text.secondary}`,
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: 20
    })
    const ButtonsRow = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
        backgroundColor: theme.palette.background.default,
        gap: 20
    })
    const handleOpenCloseStudentMic = () => {
        if (data?.micConnected) {
            socket.emit("close-student-mic", data.user_id)
        } else {
            socket.emit("open-student-mic", data.user_id);
            setMicSent(true);
        }
    }
    const handleSetScreen = (screenId) => {
        window.electron.shareScreenStudent(screenId, room_id);
        setScreenSources(false)
    }
    const chooseDisplay = async () => {
        try {
            const constraints = {
              video: {
                cursor: 'always'
              },
              audio: false
            };
            const mediaStream = await navigator.mediaDevices.getDisplayMedia(constraints);
            setStudentStream(mediaStream);
            mediaStream.getTracks().forEach(track => {
                track.onended = () => {
                  socket.emit("share-screen-close-stream-to-teacher")
                  setStudentStream(null);
                  setTutorStream(null)
                };
              });
          } catch (err) {
            setError(err.message);
          }
        
    }
    useEffect(() => {
        if (studentStream) {
            const allTracksEnded = studentStream.getTracks().every(track => track.readyState === 'ended');
            if (!allTracksEnded) {
                // if (tutorPeerRefs.current.length) tutorPeerRefs.current.map(p => p?.peer.destroy());
                // tutorPeerRefs.current = []
                socket.emit("get-teacher-socket", teacherSocket => {
                    console.log("got the teacher account ", teacherSocket)
                    if (teacherSocket) {
                        createTeacherPeer(teacherSocket, studentStream);
                        // socket.emit("get-connected-students", students => {
                        //     if (students.length) {
                        //         students.map(student => {
                        //             if (student.user_type === 0)
                        //                 createPeer(student, studentStream);
                        //         })
                        //     }
                        // })
                    }
                })
                // tutorSreamRef.current = studentStream;
                setTutorStream(studentStream);
            }
        }
      }, [studentStream]);
      const createTeacherPeer = useCallback((teacherSocket, stream) => {
        const newPeer = new Peer({
            initiator: true,
            trickle: true,
            stream,
            config: {
                iceServers: [{
                    urls: 'stun:5.134.255.233:3478'
                }
                    ,
                {
                    urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                },
                {
                    urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                }
                ]
            }
        });
        newPeer.on("signal", (data) => {
            socket.emit("student-share-screen-signal-to-teacher", { signal: data, studentSocketId: socket.id, teacherSocketId: teacherSocket.socket_id, shareScreenDimension: { x: 0, y: 0, width: stream.getVideoTracks()[0].getSettings().width, height: stream.getVideoTracks()[0].getSettings().height, fullWidth: window.innerWidth, fullHeight: window.innerHeight, PR: window.devicePixelRatio, screenSharing: true, dir: theme.direction } });
        });

        newPeer.on('error', (err) => {
            // createServerHandlerPeer(serverHandler, stream)
            console.log("the peer error is ", err)
        })
        teacherPeerRef.current = newPeer
    },[tutorStream])
    useEffect(()=>{
        socket.on("teacher-share-screen-signal", (data) => {
            console.log("got the data from the teacher ", data, teacherPeerRef.current)
            const signal = data.signal;
            teacherPeerRef.current.signal(signal)
        })
        return ()=>{
            socket.off("teacher-share-screen-signal")
            if (teacherPeerRef.current) teacherPeerRef.current.destroy();
            teacherPeerRef.current =null;

            if (studentStream) {
                studentStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        }
    },[createTeacherPeer,tutorStream])
    return (
        <AttendeeBox>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={downloadApp}
            >
                <AskedForMicWrapper>
                    <Typography variant='h4'>{t('downloadAppMessage')}</Typography>
                    <ButtonsRow>
                        <Link target="_blank" rel="noopener noreferrer" href="https://shorturl.at/JSVZ9"><Button onClick={()=>setDownloadApp(false)}  variant="contained" >{t("downloadApp")}</Button></Link>
                        <Button onClick={() => {  setDownloadApp(false) }} variant="contained" sx={{ backgroundColor: theme.palette.danger.main }}>{t("close")}</Button>
                    </ButtonsRow>
                </AskedForMicWrapper>

            </Backdrop>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={screenSources}
            >
                <Box sx={{ backgroundColor: theme.palette.background.default, display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', justifyContent: 'space-around', padding: '5rem' }}>
                    <Typography sx={{ color: theme.palette.text.secondary }}>{t("chooseAScreen")}</Typography>
                    <Box sx={{ borderRadius: '10px', display: 'flex', flexDirection: 'row' }}>
                        {screenSources && screenSources.map((screen, index) =>
                            <Box key={index} sx={{ ":hover": { border: `1px solid ${theme.palette.primary.main}`, backgroundColor: theme.palette.primary.main }, borderRadius: '5px', margin: '1rem' }} >
                                <img height={100} src={screen.thumbnail} onClick={() => handleSetScreen(screen.display_id)} alt={screen.name}
                                    style={{ width: '150px', margin: '10px' }} />
                            </Box>)}
                    </Box>
                    <Button variant="outlined"
                        sx={{
                            color: theme.palette.secondary.main,
                            borderColor: theme.palette.secondary.main,
                        }} onClick={() => setScreenSources(false)}>{t("close")}</Button>
                </Box>

            </Backdrop>
            <AttendeeInfo>
                <AttendeeStatusBox ></AttendeeStatusBox>
                <Avatar  alt={data.user_name} src={`${BaseUrl}${data?.user_image}`} sx={{display:isMobileBrowser()?'none':"inherit"}} >{getInitialForAvatar(data.user_name)}</Avatar>
                <Typography sx={{ color: data?.user_type !== 0 ? theme.palette.secondary.main : theme.palette.text.default }}>{data?.user_name}</Typography>
                {user && user.user_type === 0 && data.user_id === user.id && !meetingStarted && !isMobileBrowser() && <IconButton onClick={() => chooseDisplay()}>
                    <ScreenShareIcon sx={{ color: theme.palette.secondary.main }} /></IconButton>}
            </AttendeeInfo>
            <AttendeeControlls>
                {user && user.user_type !== 0 && data.user_id !== user.id && !meetingStarted && <IconButton disabled={micSent} onClick={() => handleOpenCloseStudentMic()}>{data?.micConnected ? <MicOffIcon sx={{ color: theme.palette.secondary.main }} /> : <MicIcon sx={{ color: micSent ? theme.palette.background.gray : theme.palette.secondary.main }} />}</IconButton>}
                {user && data.user_id !== user.id && <IconButton onClick={() => onaddPrivateMessage(data.user_id, data?.user_name)}>
                    <MailLockIcon sx={{ color: theme.palette.secondary.main }} /></IconButton>}
            </AttendeeControlls>
            {/* <video ref={videoRef} autoPlay style={{ width: '100%', height: 'auto' }} /> */}
        </AttendeeBox>

    )
}

export default Attendee