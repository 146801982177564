
import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import StudentMeetingCard from './StudentMeetingCard'
import { isMobileBrowser } from './../helpers/helperMethods/isMobileBrowser';

const StudentMeeting = ({ attendees, tutorStream, meetingStarted, handleStreamStudentAudio, handleStreamStudentCam }) => {
    const theme = useTheme();
    const containerRef = useRef()
    const [cardDimensions, setCardDimensions] = useState()

    const updateCardsSize = () => {
        try {
            const gap = 5
            if (containerRef.current && attendees.length) {
                const container = containerRef.current;
                const containerWidth = container.getBoundingClientRect().width;
                const containerHeight = container.getBoundingClientRect().height + 40;
                const aspectRatio = attendees.length === 1 ? containerWidth / containerHeight : 1.2;
                let rowCount = 1;
                let cardWidth = containerWidth;
                let cardHeight = cardWidth / aspectRatio;
                while (true) {
                    const totalGapWidth = gap * (Math.ceil(attendees.length / (rowCount !== 0 ? rowCount : 1)) - 1);
                    const totalGapHeight = gap * (rowCount - 1);
                    cardWidth = (containerWidth - totalGapWidth) / Math.ceil(attendees.length / (rowCount !== 0 ? rowCount : 1));
                    cardHeight = cardWidth / (aspectRatio);

                    if ((rowCount * cardHeight) + totalGapHeight > containerHeight) {
                        break; // Stop if the next row won't fit
                    }
                    rowCount++;
                }

                rowCount--; // Go back to the last fitting row count
                const totalGapWidth = gap * (Math.ceil(attendees.length / (rowCount !== 0 ? rowCount : 1)) - 1);
                cardWidth = (containerWidth - totalGapWidth) / Math.ceil(attendees.length / (rowCount !== 0 ? rowCount : 1)) - 5;
                cardHeight = cardWidth / aspectRatio;
                setCardDimensions({ cardWidth, cardHeight });

            }
        } catch (error) {
            console.log("the error from resizing cards is ", error)
        }


    }
    useEffect(() => {
        if (attendees.length) { updateCardsSize(); }
        window.addEventListener('resize', updateCardsSize);
        return () => {
            window.removeEventListener('resize', updateCardsSize);
        }
    }, [attendees])
    return (
        <Box ref={containerRef} style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '5px',
            left: 0,
            top: '60px',
            left: theme.direction === 'ltr' ? '0px' : isMobileBrowser()?window.innerWidth * 6 / 25 + 'px': window.innerWidth * 5 / 25 + 'px',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            position: 'absolute',
            zIndex: 1200,
            width: isMobileBrowser()?(window.innerWidth * 19 / 25) + 'px': (window.innerWidth * 20 / 25) + 'px',
            height: 'calc(100vh - 60px)',
            visibility: meetingStarted ? 'visible' : 'hidden',
            backgroundColor: theme.palette.background.gray
        }}>
            {attendees.length && cardDimensions && attendees.map((attendee, index) => (
                <StudentMeetingCard key={index} cardDimensions={cardDimensions} attendee={attendee} toturStream={tutorStream} attendeesCount={attendees.length} handleStreamStudentAudio={(attendee) => handleStreamStudentAudio(attendee)} handleStreamStudentCam={(attendee) => handleStreamStudentCam(attendee)} />
            ))}
        </Box>
    )
}

export default StudentMeeting