import React, { useEffect } from 'react';
import { ThemeProvider, Box } from '@mui/material';
import { HashRouter, BrowserRouter } from "react-router-dom";
import { ColorModeContext, useMode, LangContext } from './layout/theme'
import { AuthContenxtProvider } from './pages/auth/context/AuthContext';
import { BreadCrumbContextProvider } from './pages/master/context/BreadCrumbContext';
import { store } from './config/store'
import { Provider } from 'react-redux';
import Router from './routes'
import { useLocalStorage } from './config/localStorage';

function App() {
    const [theme, colorMode, language] = useMode();
    const { getItem, removeItem } = useLocalStorage();
    const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    // const user = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        const room_timer = getItem("room-timer");
        if (room_timer) removeItem("room-timer")
    }, [])
    return (
        <AuthContenxtProvider>
            {/* <ServerHandler /> */}
            <BreadCrumbContextProvider>
                <Provider store={store}>
                    <LangContext.Provider value={language}>
                        <ColorModeContext.Provider value={colorMode}>
                            <ThemeProvider theme={theme}>
                                <Box sx={{ direction: theme.direction }}>
                                    {dev ? (<BrowserRouter>
                                        <Router />
                                    </BrowserRouter>) : (<HashRouter>
                                        <Router />
                                    </HashRouter>)}
                                </Box>
                            </ThemeProvider>
                        </ColorModeContext.Provider>
                    </LangContext.Provider>
                </Provider >
            </BreadCrumbContextProvider>
        </AuthContenxtProvider>
    )
}

export default App