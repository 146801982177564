import React from 'react'
import { Box, useTheme, styled } from '@mui/material'

import MasterAppBar from '../master/components/MasterAppBar'
import CourseContent from './components/CourseContent'
import CourseProgressBar from './components/CourseProgressBar'
import CourseSections from './components/CourseSections'
import Footer from '../master/components/Footer'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { useParams } from 'react-router-dom'
function Course() {
  const params = useParams()
  const theme = useTheme();
  const CourseContentContainer = styled(Box)(({ theme }) => ({
    width: '80vw',
    margin: 'auto',
    padding: '2rem',
    border: `1px solid ${theme.palette.background.gray}`,
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      width: '95vw',
      padding: '0.5rem',
    },
  }));
  return (
    <ScopedCssBaseline>
      <Box>
        <MasterAppBar crumb={true} />
        <CourseProgressBar data={{ progress: 25 }} />
        <CourseContentContainer>
          <CourseSections course_id={params?.id} />
          {/* <CourseContent course_id={params?.id}/> */}
        </CourseContentContainer>
        <Footer />
      </Box>
    </ScopedCssBaseline >

  )
}

export default Course