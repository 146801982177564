import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Session from './Session';
import { tokens } from '../../../layout/theme';
import API from '../../../config/API';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import Skeleton from '../../dashbord/components/helpers/Skeleton';

const Accordion = styled((AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...AccordionProps} />
))(({ theme }) => ({
    //border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0',
    },
}));

const AccordionSummary = styled((AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...AccordionSummaryProps}
    />
))(({ theme }) => ({
    // backgroundColor: theme.palette.background.default,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const ClassHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTop: '1px solid rgb(0, 0, 0)',
}));
export default function CourseSections({ course_id }) {
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const [expanded, setExpanded] = useState('panel00');
    const [sections, setSections] = useState([])
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const [teacherUserId,setTeacherUserId] = useState(false)
    const colors = tokens(theme.palette.mode);
    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

        const getSectionsData = () => {
            setLoading(true)
            API.get(`get_content_course_details?course_id=${course_id}`, {
                headers: {
                    Authorization: user.token,
                    "Content-Type": 'application/json'
                }
            }).then(data => {
                if (data.status === 200 
                    // && data?.data?.courseDetails.length
                    ) {
                    console.log("the data is ", data.data.data[0].session_categorized_by_section)
                    setTeacherUserId(data?.data?.data[0]?.teacher_id)
                    setSections(data?.data?.data[0]?.session_categorized_by_section)
                } else {
                    throw Error('Someting wrong happened')
                }
                // console.log("the course data is ", data)
                setLoading(false)
            }).catch(err => { console.log("the error is ", err); setLoading(false) })
        }
    useEffect(() => {
        if (user && course_id) getSectionsData()
    }, [user, course_id])
    return (
        <>
            {(loading && !sections.length) ? <>
                {Array(1).fill().map((_, index) => <Skeleton key={index} width={'80vw'} height={'60vh'} />)}
            </> :
                <Box>
                    {sections.length > 0 &&
                        <>
                        {sections[0].time_class?<>
                            {sections.map((course_class, index_c) => (
                                <>
                                    <ClassHeader sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                                            <Typography variant='h3' sx={{ color: theme.palette.text.default, margin: '0px 40px' }}>{course_class.title}</Typography>
                                    </ClassHeader>
                                    {course_class.session_categorized_by_section.map((section, index) => (
                                    <Accordion key={index} expanded={expanded === 'panel' + index_c+ index} onChange={handleChange('panel' +index_c+ index)} sx={{ backgroundColor: index % 2 === 0 ? colors.primary[200] : theme.palette.background.gray }}>
                                        <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                                                <Typography variant='h3' sx={{ color: theme.palette.text.default, margin: '0px 20px' }}>{section.title}</Typography>
                                            </Box>

                                        </AccordionSummary>
                                        <AccordionDetails >
                                            {teacherUserId && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                {section.session.map((session, index) => (
                                                    <Session data={session} key={session.id} courseId={section.course_id} teacherUserId={teacherUserId} />
                                                ))}
                                            </Box>}
                                            

                                        </AccordionDetails>
                                    </Accordion>))}
                                </>
                                
                            ))}
                     
                        </>:
                        <>
                            {sections.map((section, index) => (
                                <Accordion key={index} expanded={expanded === 'panel0' + index} onChange={handleChange('panel0' + index)} sx={{ backgroundColor: index % 2 === 0 ? colors.primary[200] : theme.palette.background.gray }}>
                                    <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                                            <Typography variant='h3' sx={{ color: theme.palette.text.default, margin: '0px 20px' }}>{section.title}</Typography>
                                        </Box>

                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            {section.session.map((session, index) => (
                                                <Session data={session} key={session.id} courseId={section.course_id} teacherUserId={teacherUserId} />
                                            ))}
                                        </Box>

                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </>}
                               </>
                    }
                </Box>}
        </>


    );
}