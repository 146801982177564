import React, { useState, useRef, useEffect, useContext } from 'react'
import { useLogout } from '../../auth/hooks/useLogout';
import { BreadCrumbContext } from '../../master/context/BreadCrumbContext';
import { socket } from '../../../config/SocketApi';
import { useNavigate } from 'react-router-dom';
import Peer from 'simple-peer';
import { Backdrop, Box, Button, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobileBrowser } from '../helpers/helperMethods/isMobileBrowser';
const StudentBoard = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { logout } = useLogout();
    const navigate = useNavigate();
    const { breadCrumbSetter } = useContext(BreadCrumbContext)
    const [tutorStream, setTutorStream] = useState();
    const [notAuthenticated, setNotAuthenticated] = useState(false);
    const studentVideoRef = useRef()
    const [whiteBoardDimensions, setWhiteBoardDimensions] = useState()
    const studentPeerRef = useRef();
    const animationFrameRef = useRef();
    const handleLogOut = () => {

        logout();
        breadCrumbSetter(0)
        navigate("/")
    };
    const createStudentPeerWhiteBoard = (data) => {
        if (data?.signal?.type === 'offer') {
            const newPeer = new Peer({
                initiator: false,
                trickle: true,
                config: {
                    iceServers: [{
                        urls: 'stun:5.134.255.233:3478'
                    }
                        ,
                    {
                        urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                        username: 'turnTorbet',
                        credential: 'Torbet@2023'
                    },
                    {
                        urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                        username: 'turnTorbet',
                        credential: 'Torbet@2023'
                    }
                    ]
                }
            });

            newPeer.signal(data.signal)
            newPeer.on("stream", (stream) => {
                if (tutorStream) {
                    tutorStream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
                if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
                setTutorStream(stream)
            })
            newPeer.on("signal", (signal) => {
                socket.emit("white-board-student-signal", { signal, tutor_id: data.tutor_id });
            });
            newPeer.on('error', (err) => { console.log("the peer error is ", err) })
            studentPeerRef.current = newPeer;
        } else {
            studentPeerRef.current.signal(data.signal)
        }
        console.log("whiteboard dimensions ", data.whiteBoardDimensions)
        setWhiteBoardDimensions(data?.whiteBoardDimensions)
    }
    
    const createStudentPeerShareScreen = (data) => {
        if (data?.signal?.type === 'offer') {
            const newPeer = new Peer({
                initiator: false,
                trickle: true,
                config: {
                    iceServers: [{
                        urls: 'stun:5.134.255.233:3478'
                    }
                        ,
                    {
                        urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                        username: 'turnTorbet',
                        credential: 'Torbet@2023'
                    },
                    {
                        urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                        username: 'turnTorbet',
                        credential: 'Torbet@2023'
                    }
                    ]
                }
            });

            newPeer.signal(data.signal)
            newPeer.on("stream", (stream) => {
                if (tutorStream) {
                    tutorStream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }
                if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
                setTutorStream(stream)
            })
            newPeer.on("signal", (signal) => {
                socket.emit("screen-sharing-student-signal", { signal, tutor_id: data.tutor_id });
            });
            newPeer.on('error', (err) => { console.log("the peer error is ", err) })
            studentPeerRef.current = newPeer;
        } else {
            studentPeerRef.current.signal(data.signal);
        }
        console.log("the white board", data.whiteBoardDimensions)
        setWhiteBoardDimensions(data?.whiteBoardDimensions)
    }
    useEffect(() => {

        socket.on("white-board-totur-signal", (data) => createStudentPeerWhiteBoard(data))
        socket.on("screen-sharing-totur-signal", (data) => createStudentPeerShareScreen(data))
        socket.on("not-atuthenticated", () => {
            socket.disconnect();
            setNotAuthenticated(true)
            setTimeout(() => {
                handleLogOut();
            }, 5000);
        })
        socket.on("connect_error", error => console.log(error))
        return () => {
            socket.off('connect_error');
            socket.off("white-board-totur-signal");
            socket.off("screen-sharing-totur-signal");
            if (tutorStream) {
                tutorStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
            setTutorStream(null)
            if (studentPeerRef.current) {
                studentPeerRef.current.destroy();
                studentPeerRef.current = null
            }
        }
    }, [])

    useEffect(() => {
        if (studentVideoRef.current && tutorStream) {
            studentVideoRef.current.srcObject = tutorStream;
        }
    }, [tutorStream])

    const NotAuthenticatedWrapper = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.text.secondary}`,
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: 20
    })
    const ButtonsRow = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,
        backgroundColor: theme.palette.background.default,
        gap: 20
    })
    const widthRatio = (TRegionWidth)=>{
        const SDivWidth = isMobileBrowser()?(window.innerWidth * 19 / 25): (window.innerWidth * 20 / 25);
        const SDivWidthRatio = SDivWidth / TRegionWidth
        console.log("the width ratio is ", SDivWidthRatio)
        return SDivWidthRatio
    }
    const calculateWidth = (TFullWidth, TRegionWidth) => {
        console.log("the ratio width on height", window.screen.availWidth , window.screen.availHeight)
        return widthRatio (TRegionWidth) * TFullWidth  ;
    }
    const calculateHeight = (TFullHeight, TRegionWidth) => {
        console.log("the ratio width on height", window.screen.availWidth , window.screen.availHeight)
        return widthRatio (TRegionWidth) * TFullHeight  ;
    }
    return (
        <>
            <Box sx={{ width: '100% !important;' }}>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={notAuthenticated}
                >
                    <NotAuthenticatedWrapper>
                        <Typography variant='h4'>{t('not_authorized')}</Typography>
                        <ButtonsRow>
                            <Button onClick={() => { setNotAuthenticated(false); handleLogOut(); }} variant="contained" sx={{ backgroundColor: theme.palette.danger.main }}>{t("ok")}</Button>
                        </ButtonsRow>
                    </NotAuthenticatedWrapper>
                </Backdrop>
            </Box>
            <>
                    {/* {!whiteBoardDimensions.screenSharing ?
                        <Box sx={{ flex: 21, overflow: 'hidden', width: `${(window.innerWidth * 21 / 26)}px`, height: `${whiteBoardDimensions.height}px` }}>
                        <video ref={studentVideoRef} playsInline autoPlay muted style={{transform: `translate(${calculateX(theme.direction, whiteBoardDimensions.dir, whiteBoardDimensions.PR, whiteBoardDimensions.screenSharing, whiteBoardDimensions.fullWidth, whiteBoardDimensions.fullHeight)}px, ${-whiteBoardDimensions.y}px)`,transformOrigin: 'top left',objectFit: 'cover',  width: `${whiteBoardDimensions.fullWidth}px`}} />
                </Box>
                    :  */}
                    {/* } */}
                </>
            {whiteBoardDimensions &&
                    <Box sx={{direction:'ltr', flex: isMobileBrowser()?19: 20, overflow: 'hidden', width:isMobileBrowser()? `${(window.innerWidth * 19 / 25)}px !important`: `${(window.innerWidth * 20 / 25)}px !important`, height:isMobileBrowser()?`${(window.innerWidth * 19 / 25) * whiteBoardDimensions.height /whiteBoardDimensions.width}px `:`${(window.innerWidth * 20 / 25) * whiteBoardDimensions.height /whiteBoardDimensions.width}px `}}>
                        <video ref={studentVideoRef} playsInline autoPlay muted style={{ width: calculateWidth(whiteBoardDimensions.fullWidth, whiteBoardDimensions.width) +'px', height: calculateWidth(whiteBoardDimensions.fullHeight, whiteBoardDimensions.width) +'px', objectPosition: `${-whiteBoardDimensions.x * widthRatio(whiteBoardDimensions.width)}px ${-whiteBoardDimensions.y * widthRatio(whiteBoardDimensions.width)}px` , objectFit:'cover'}} />
                    </Box>
            }
            
        </>
    )
}

export default StudentBoard