import axios from 'axios';
// export const BaseUrl = `https://torbet-release.perla-tech.com`;
export const BaseUrl = `https://amanodoo.perla-tech.com`;
// export const BaseUrl = `http://localhost:8069`;
let userLang = navigator.language || navigator.userLanguage;
let arabic = userLang.includes('ar') ? 'ar/':''
export default axios.create({
     baseURL: `https://amanodoo.perla-tech.com/`
    //  baseURL: `http://localhost:8069/`
    // baseURL: `https://torbet-release.perla-tech.com/${arabic}`
    //baseURL: baseUrl
});