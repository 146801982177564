import React, { useState, useRef } from 'react'
import { Box, IconButton, styled, useTheme } from '@mui/material'
import { SketchPicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';
function ColorPicker({ onSelectColor, colorSelected, shareScreen = false }) {
    const theme = useTheme();
    const [colorPickerVisible, setColorPickerVisible] = useState(false)
    const ColorBoxContainer = styled(Box)(({ theme }) => ({
        width: !shareScreen ? '20px' : '15px',
        height: !shareScreen ? '15px' : '12px',
        padding: '1px',
        marginLeft: shareScreen ? '5px' : 0,
        border: `1px solid ${theme.palette.text.default}`,
        cursor: 'pointer',
        backgroundColor: "white"
    }))
    const ColorBox = styled(Box)(({ theme }) => ({
        width: '100%',
        height: '100%',
        backgroundColor: colorSelected ?? "#fff",
    }))
    const ColorPickerContainer = styled(Box)(({ theme }) => ({
        position: 'absolute',
        left: theme.direction === 'ltr' ? '22px' : "0px",
        right: theme.direction === 'rtl' ? '22px' : "0px",
        top: '0px',

    }))
    const setClickable = () => {
        if (!shareScreen) return;
        window.electron.setClickable();
    }
    const setClickThrough = () => {
        if (!shareScreen) return;
        window.electron.setClickThrough();
    }
    const colorPickerRef = useRef()
    const colorBoxRef = useRef()
    return (
        <Box onMouseEnter={() => setClickable()} onMouseLeave={() => setClickThrough()} sx={{ position: 'relative', zIndex: 1000 }}>
            <ColorBoxContainer ref={colorBoxRef} onClick={() => setColorPickerVisible(true)}>
                <ColorBox></ColorBox>
            </ColorBoxContainer>
            <ColorPickerContainer ref={colorPickerRef} >
                {colorPickerVisible && (<><SketchPicker color={colorSelected} onChange={(color) => onSelectColor(color.hex)} />
                    <IconButton sx={{ position: 'absolute', top: '-35px', right: '0px' }} onClick={() => setColorPickerVisible(false)}>
                        <CloseIcon sx={{ color: theme.palette.danger.main }} />
                    </IconButton></>)}
            </ColorPickerContainer>
        </Box>

    )
}

export default ColorPicker