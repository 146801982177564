import React, { useContext } from 'react'
import { Box, CardMedia, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { BreadCrumbContext } from '../../master/context/BreadCrumbContext';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../../config/API';
function CourseCard({ data}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { breadCrumbAdder } = useContext(BreadCrumbContext)
    const ContainerBox = styled(Box)(() => ({
        padding: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        backgroundColor: theme.palette.background.gray,
        borderRadius: '0.5rem',
        cursor: 'pointer',
        width: '100%',
        // maxWidth: '20rem',
        gap: '15px',
        maxHeight: '8rem'

    }));
    const DottBox = styled(Box)(() => ({
        width: '10px',
        height: '10px',
        backgroundColor: theme.palette.success.main,
        borderRadius: '50%',
        flex: 1

    }));
    const BorderLinearProgress = styled(LinearProgress)(() => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.background.gray,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.success.main,
        },
    }));
    const handleNavigation = () => {
        breadCrumbAdder({ translatable: false, title: data.title, route: `/course_details/${data.course_id}` })
        navigate(`/course_details/${data.course_id}`)
    }
    return (
        <ContainerBox onClick={() => handleNavigation()}>
            <Box sx={{ flex: 2 }}>
                <CardMedia component="img"
                    image={data.img_path ? BaseUrl+data.img_path : process.env.PUBLIC_URL+"/assets/favicon.png"}
                    sx={{ width: '5rem' }}
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                <Typography variant='h5' sx={{ mb: 2, color: theme.palette.text.default }}>{data.title}</Typography>
                <BorderLinearProgress variant="determinate" value={data.progress ?? Math.random(100)} />
                <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>{data.progress}% progress</Typography>
            </Box>
            <Box>
                <DottBox />
            </Box>
        </ContainerBox>
    )
}

export default CourseCard