export const getInitialForAvatar = (name)=>{
    let nameArr = name.split(" ");
    let placeHolder = "";
    if(nameArr.length>0){
        nameArr.map((n)=>{
            placeHolder += n[0].toUpperCase();
        })
    }else{
        placeHolder = name[0].toUpperCase();
    }
    return placeHolder;
}