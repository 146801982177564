import React from 'react'
import { Box, styled, Tooltip, Typography, useTheme } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import { tokens } from './../../../layout/theme';
import MailLockIcon from '@mui/icons-material/MailLock';
import { BaseUrl } from '../../../config/API';
import { useTranslation } from 'react-i18next';
import { getInitialForAvatar } from '../helpers/helperMethods/getInitials';
import { isMobileBrowser } from '../helpers/helperMethods/isMobileBrowser';
function Comment({ data }) {
    console.log("the data is ", data)
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode)
    const CommentBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px',
        padding: '8px 24px 8px 24px',
        maxWidth: '100%',
        wordBreak: 'break-word'

    })
    const MessageBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        border: `1px solid ${colors.primary[100]}`,
        borderRadius: '8px',
        backgroundColor: colors.primary[100],
        padding: '5px 5px 5px 5px'

    })
    const MessageBoxHeader = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '8px',
        alignItems: 'center'

    })
    return (
        <CommentBox>
            <Avatar alt={data?.fullName} src={`${BaseUrl}${data?.imgPath}`} sx={{display:isMobileBrowser()?'none':'inherit'}} >{getInitialForAvatar(data?.fullName)}</Avatar>
            <MessageBox>
                <MessageBoxHeader><Typography variant='h5' fontSize="0.9em" fontWeight="800" sx={{ color: data?.userType !== 0 ? theme.palette.secondary.main : theme.palette.text.default }}>{data?.fullName}</Typography>{data.isPrivate && <Tooltip title={t("privatMessage")}><MailLockIcon sx={{ color: theme.palette.secondary.main, width: '15px' }} /></Tooltip>}</MessageBoxHeader>
                {data?.message && <Typography variant='h6' fontSize="0.8em" sx={{ color: theme.palette.text.default }}>{data?.message}</Typography>}
                <Typography textAlign="right" fontSize="0.8em" sx={{ color: theme.palette.text.secondary }}>{data?.timeSent}</Typography>
            </MessageBox>

        </CommentBox>
    )
}

export default Comment