import React, { useState, useEffect } from 'react';
import {  Box, Container, CardMedia, Backdrop, CssBaseline, TextField, Button, Snackbar, ScopedCssBaseline, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import API from '../../../config/API';
import MasterAppBar from '../../master/components/MasterAppBar';
import { socket } from '../../../config/SocketApi';
import AdmitionRequests from './AdmitionRequests';
const PrepareMeeting = () => {
    const navigate = useNavigate();
    const {m_id,s_id} = useParams();
    const { t } = useTranslation()
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch, user } = useAuthContext();
    const [message,setMessage] = useState()
    const [errorFound, setErrorFound] = useState(false)
    console.log("the user is ", user)
    const LoginContainer = styled(Box)(({ theme }) => ({

        '& .react-tel-input .flag-dropdown': {
            top: '50% !important',
            bottom: '30% !important',
        }, '& .special-label': {
            display: 'none !important'
        }, '& .selected-flag .arrow': {
            left: theme.direction === 'ltr' ? '30px' : '-30px'
        }, '& .react-tel-input .selected-flag .flag': {
            left: '20%'
        }, '& .react-tel-input .country-list .country-name': {
            color: theme.palette.text.secondary
        }
        ,
        '& input[name="phone_number"]': {
            direction: 'ltr !important',
            padding: theme.direction === 'ltr' ? '18.5px 14px 18.5px 58px !important' : '18.5px 58px 18.5px 18.5px !important'
        }



    }))

    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required(t('fullNameRequired'))
            .min(3, t('fullNameShort'))
            .max(30, t('fullNameLong')),
    });


    const formik = useFormik({
        initialValues: {
            fullName: '',
        },
        validationSchema: validationSchema,
    });
    // function cleanPhoneNumber(phoneNumber) {
    //     const unwantedChars = /[+()\-\s]/g;
    //     return phoneNumber.replace(unwantedChars, '');
    // }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(user) {
            setErrorFound(true)
            setError(t("alreadyRegistered"))
            return;
        }
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const fullName = data.get('fullName');
        const body = {
            username:fullName.replace(" ",""),
            user_type:0,
            password:fullName.replace(" ","") + Date.now(),
            mobile_number:"963997" + Math.ceil(Math.random() * 1000000),
            full_name:fullName,
            email:fullName.replace(" ","")+ "@torbet.org"
        }

        console.log("the user name is ", fullName,body.mobile_number)
        API.post("/register",body,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            localStorage.setItem('user', JSON.stringify(res.data.details))
            dispatch({ type: 'LOGIN', payload: res.data.details })
            console.log("the user data is ", res.data.details)
            await joinMeeting(res.data.details);
            // setIsLoading(false);
            // navigate("/dashboard")
        }).catch(err => {
            setIsLoading(false)
            console.log("the error is ", err)
            setError(err.response.data.message)
            console.log(err)
        })
        // const password = data.get('password');
        // await login(phoneNumber, password)
    };
    const joinMeeting = async (userData)=>{
        // const formData = new FormData();
        // formData.append('course_id', m_id);
        API.post(`/add_enroll_course?course_id=${m_id}`,{},
            {
                headers: {
                    Authorization: userData.token,
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if(res){
                    console.log("the res is ", res)
                    setMessage(t("waitingForApproval"))
                    socket.emit("ask-for-admition",{})
                    // navigate(`/class_room/${s_id}/false`)
                }else{
                    setError("حدث خطأ ما اثناء انشاء الاجتماع يرجى المحاولة لاحقا")
                }
                // console.log("the res is ", `/class_room/${res?.data?.details[0]?.session_obj[0]?.id}/${userData.id}`)
                // localStorage.setItem('user', JSON.stringify(res.data.details))
                // dispatch({ type: 'LOGIN', payload: res.data.details })
                setIsLoading(false);
                // navigate("/dashboard")
            }).catch(err => {
                console.log("the error is ", err)
                setIsLoading(false)
                setError(err.response.data.message)
                console.log(err)
            })
    }
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorFound(false);
    }
    useEffect(() => {
        if (error) setErrorFound(true);
    }, [error])
    useEffect(()=>{
        if(user&&s_id){
            socket.connect().auth = { token: user?.token, room_id: s_id, user_type: 0, user_id: user?.id, user_name: user?.full_name, user_image: user?.img_path }
            joinMeeting(user);
        }
        socket.on("admition-approved",()=>{
            console.log("got here")
            navigate(`/class_room/${s_id}/false`)
        })
        socket.on("admition-rejected",()=>{
            console.log("got here")
            setMessage(t("admitionRejected"))
        })
        return ()=>{
            socket.off("admition-approved")
            socket.off("admition-rejected")
            socket.disconnect()
        }
    },[user, s_id])
    return (
        <ScopedCssBaseline>
            <MasterAppBar crumb={false} virtualRoom={false} />
            <Container component="main" maxWidth='sx' sx={{ height: '100%', paddingTop: '2rem' }}>
                <Snackbar
                    open={errorFound}
                    autoHideDuration={5000}
                    onClose={() => setErrorFound(false)}
                    message={error ?? ('error500')}
                    action={<Button color="primary" size="small" onClick={handleCloseAlert} sx={{ m: '0px 20px' }}
                    >
                        {t("ok")}
                    </Button>}

                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CardMedia component="img"
                        image="./assets/loading.gif"
                        sx={{ width: '8rem' }}
                    />
                </Backdrop>
                <CssBaseline />
                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row-reverse', flexWrap: 'wrap', justifyContent: 'space-around', justifyItems: 'center' }} >
                    <CardMedia component="img"
                        image="./assets/login.svg"
                        sx={{ width: '30%' }}
                    />
                     <Box
                        sx={{

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '70%'
                        }}
                    >
                        <LoginContainer component="form" onSubmit={handleSubmit} sx={{ width: '60%' }}>
                        <TextField
                                margin="normal"
                                required
                                fullWidth
                                autoFocus
                                disabled = {message?true:false}
                                name="fullName"
                                placeholder={t("fullName")}
                                type='text'
                                value={formik.values.fullName}
                                onChange={(e) => { formik.handleChange(e);}}
                                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                            />
                            <Button
                                type="submit"
                                disabled = {message?true:false}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("joinMeeting")}
                            </Button>
                          {/*  <PhoneInput
                                country={'sy'}
                                value={formik.values.phone_number}
                                onChange={phone_number => { formik.setFieldValue('phone_number', phone_number); setFeildChanging('phone_number') }}
                                inputStyle={{ width: '100%', marginTop: 16, direction: theme.direction, backgroundColor: theme.palette.background.default, color: theme.palette.text.secondary }}
                                // InputLabelProps={{ style: { color: 'red' } }}
                                placeholder=""
                                buttonStyle={{ display: 'block' }}
                                title=''
                                inputProps={{
                                    name: 'phone_number',
                                    required: true,
                                    autoFocus: feildChanging === 'phone_number' ? true : false
                                }}
                            />
                            {formik.touched.phone_number && formik.errors.phone_number && (
                                <div style={{ color: 'red', marginTop: '.5rem' }}>{formik.errors.phone_number}</div>
                            )}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"

                                placeholder={t("password")}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={formik.values.password}
                                onChange={(e) => { formik.handleChange(e); setFeildChanging('password') }}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                InputProps={{
                                    autoFocus: feildChanging !== 'phone_number' ? true : false,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t("login")}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2" style={{ color: 'grey' }}>
                                        {t("forgotpassword")}
                                    </Link>
                                </Grid>
                            </Grid>*/}
                        </LoginContainer>
                    </Box> 
                </Box>
            <AdmitionRequests message={message}/>
            </Container>
        </ScopedCssBaseline>


    );
}
export default PrepareMeeting;