import axios from 'axios';
import { io } from "socket.io-client";


//socket from syria and recording from usa
// export const baseUrl = `https://virtual-ws.perla-tech.com/`
// export const baseUrlRecording = `https://torbet-recording.perla-tech.com/`

// //from local host
// export const baseUrl = `http://localhost:9031/`
// export const baseUrlRecording = `http://localhost:9051/`

//lawyer 
export const baseUrl = `https://aman-socket.perla-tech.com/`
export const baseUrlRecording = `https://amanrecording.perla-tech.com/`

export default axios.create({
    baseURL: baseUrl
});

export const socket = io(baseUrl, { autoConnect: false });
export const recordingSocket = io(baseUrlRecording, { autoConnect: false });