import React from 'react'
import { Box, styled, Avatar, Typography, useTheme, Rating } from '@mui/material'

import { tokens } from '../../../layout/theme';
import { getInitialForAvatar } from '../../virtualClassRoom/helpers/helperMethods/getInitials';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
function Review({ data }) {
    const theme = useTheme()
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const colors = tokens(theme.palette.mode)
    const ReviewContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '16px',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.background.gray}`,
    }))
    const ReviewInfo = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '16px',
        alignItems: 'center',

    }))
    const ReviewHeader = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '16px',
        alignItems: 'center',

    }))
    const TutorInfo = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    }))
    const VoteBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '16px',
        alignItems: 'center',

    }))
    return (
        <ReviewContainer>
            <ReviewInfo>{user&&<Avatar alt={user.full_name} src='./assets/2.jpg' >{getInitialForAvatar(user.full_name)}</Avatar>}
                <TutorInfo>
                    <ReviewHeader>
                        <Typography variant='h5'>{data.username}</Typography>
                        <Rating name="read-only" value={data.stars} readOnly />
                    </ReviewHeader>

                    <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>{data.review}</Typography>
                </TutorInfo>
            </ReviewInfo>
            <VoteBox>
                <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}  >{data.date}</Typography>
            </VoteBox>
        </ReviewContainer>
    )
}

export default Review