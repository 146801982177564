import React from 'react'
import { Box, styled, useTheme } from '@mui/material'
import MasterAppBar from '../master/components/MasterAppBar'
import SearchBarInput from '../master/components/SearchBarInput'
import Events from './components/Events'
import MainCalendar from './components/MainCalendar'
import CourseCards from './components/CourseCards'
import Footer from '../master/components/Footer'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
function Dashboard() {
  const theme = useTheme();

  return (
    <ScopedCssBaseline>
      <Box>
        <MasterAppBar crumb={true} />
        <SearchBarInput />
        <MainBox>
          <Events sx={{ alignItems: 'center' }} />
          <MainCalendar />
          <CourseCards />
        </MainBox>
        <Footer />
      </Box>
    </ScopedCssBaseline>


  )
}
// const MainBox = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   //gap: '5rem',
//   justifyContent: 'space-around',
//   //justifyItems: 'center',
//   //justifyItems: 'center',
//   alignItems: 'flext-start',
//   marginTop: '3rem',
//   flexWrap: 'wrap',
// }));
const MainBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flext-start',
  justifyItems: 'center',
  justifyContent: 'center',
  marginTop: '2rem',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    // gap: '8rem',
  },
  [theme.breakpoints.between('xs', 'lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // gap: '1rem',
  },

}))
export default Dashboard;

