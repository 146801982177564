import { configureStore } from "@reduxjs/toolkit";
import tabsSlice from "../features/tabs/tabsSlice";
import screenSharingSlice from "../features/screenSharing/screenSharingSlice";
import meetingSlice from "../features/meeting/meetingSlice";

export const store = configureStore({
    reducer: {
        tabs: tabsSlice,
        screenSharing: screenSharingSlice,
        meeting: meetingSlice,
    }
})