import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Avatar, Typography, useTheme } from '@mui/material';
import { ColorModeContext, tokens, LangContext } from '../../../layout/theme';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useTranslation } from 'react-i18next';
import { useLogout } from '../../auth/hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from './BreadCrumb';
import { BreadCrumbContext } from '../context/BreadCrumbContext';
import { BaseUrl } from '../../../config/API';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import { getInitialForAvatar } from '../../virtualClassRoom/helpers/helperMethods/getInitials';

export default function MasterAppBar({ crumb, virtualRoom }) {
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const theme = useTheme();
    const { logout } = useLogout();
    const navigate = useNavigate();
    const { breadCrumbSetter } = useContext(BreadCrumbContext)
    const localLang = JSON.parse(localStorage.getItem('lang'));
    const [lang, setLang] = useState(localLang?.lang ?? 'en')
    const colorMode = useContext(ColorModeContext);
    const language = useContext(LangContext);

    const colors = tokens(theme.palette.mode)
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleLogOut = () => {
        logout();
        breadCrumbSetter(0)
        navigate("/")
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const changeLanguage = () => {
        setLang(prev => prev === "ar" ? "en" : "ar");
        language.toggleLanguage();


    };

    const handleNavigation = () => {
        breadCrumbSetter(0)
        navigate('/')
    }
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLogOut}>{t("logout")}</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {!virtualRoom && <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>{t("messages")}</p>
            </MenuItem>}
            {!virtualRoom && <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>{t("notifications")}</p>
            </MenuItem>}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>{t("profile")}</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ height: '60px', flexGrow: 1, position: 'sticky', display: 'flex', flexDirection: 'row', padding: '0px 1rem', justifyContent: 'center', justifyItems: 'center', top: 0, zIndex: 200, backgroundColor: colors.primary[100] }}>


            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                <IconButton onClick={() => handleNavigation()}>
                    <img src={`${process.env.PUBLIC_URL}/assets/window_logo.png`} width="60" height={30} />
                </IconButton>

            </Box>

            {crumb && (<BreadCrumb />)}
            <Box >
                {!virtualRoom && <>
                    <IconButton size="large" onClick={() => changeLanguage()}>
                        <Typography variant='h5'>{lang === "en" ? "ع" : "EN"}</Typography>
                    </IconButton>
                    <IconButton size="large" onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            <LightModeIcon />
                        ) : (
                            <DarkModeIcon />
                        )}
                    </IconButton>
                </>}
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    onContextMenu={(e) => e.preventDefault()}
                    color="inherit"
                >
                    {user && <Avatar src={`${BaseUrl}${user.img_path}`} >{getInitialForAvatar(user.full_name)}</Avatar>}
                </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    onContextMenu={(e) => e.preventDefault()}
                    color="inherit"
                >
                    <MoreIcon sx={{ color: theme.palette.icon.main }} />
                </IconButton>
            </Box>

            {!virtualRoom && renderMobileMenu}
            {!virtualRoom && renderMenu}
        </Box>
    );
}