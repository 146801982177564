import React, { useState } from 'react'
import { styled, Box, TextField, Button } from '@mui/material'
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next'

function AddReview() {
    const [ratingValue, setRatingValue] = useState(0);
    const { t } = useTranslation();
    const ContainerBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-start'
    }))
    const Review = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }))
    return (
        <ContainerBox>
            <Review>
                <Rating
                    name="simple-controlled"
                    value={ratingValue}
                    onChange={(event, newValue) => {
                        setRatingValue(newValue);
                    }}
                />
                <TextField size='small' placeholder={t("yourreview")} sx={{ width: '300px' }} />
            </Review>
            <Button variant='contained' sx={{ width: 'max-content' }}>{t("addreview")}</Button>
        </ContainerBox>
    )
}

export default AddReview