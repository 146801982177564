import React from 'react';
import {
    Route,
    Routes,
} from "react-router-dom";
import ClassRoom from '../pages/virtualClassRoom';
import LogIn from '../pages/auth/components/login';
import Dashboard from '../pages/dashbord'
import Course from '../pages/Course'
import MyEvents from '../pages/events';
import MyCourses from '../pages/courses';
import Session from './../pages/session';
import ShareScreen from './../pages/virtualClassRoom/components/ShareScreen'
import ClassRoomDashboard from '../pages/classRoomDashboard';
import { useAuthContext } from '../pages/auth/hooks/useAuthContext';
import StudentShareScreen from '../pages/virtualClassRoom/helpers/studentShareScreen';
import PrepareExternalLinkAttendance from '../pages/shared/PrepareExternalLinkAttendance';
import PrepareMeeting from '../pages/meeting/components/PrepareMeeting';
export default function Router() {
    const { user } = useAuthContext()
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/meeting/:m_id/:s_id" element={<PrepareMeeting />} />
            <Route path="/course_details/:id" element={<Course />} />
            <Route path="/all_events" element={<MyEvents />} />
            <Route path="/all_courses" element={<MyCourses />} />
            <Route path="/class_room/:s_id/:tu_id" element={<ClassRoom />} />
            <Route path="/class_room_dashboard/:s_id" element={<ClassRoomDashboard />} />
            <Route path="/share_screen/:room_id" element={<ShareScreen />} />
            <Route path="/share_screen_student/:room_id" element={<StudentShareScreen />} />
            <Route path="/session/:id" element={<Session />} />
            <Route path="/attend_session/:session_id/:user_token" element={<PrepareExternalLinkAttendance />} />
        </Routes>
    )
}

