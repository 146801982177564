import React from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, styled, Typography, useTheme } from '@mui/material';



function CourseProgressBar({ data }) {
    const theme = useTheme();
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.background.gray,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.success.main,
        },
    }));
    return (
        <Box sx={{ width: '50vw', height: '50px', position: 'relative', margin: 'auto', marginTop: '50px' }}>
            <BorderLinearProgress variant="determinate" value={data.progress ?? Math.random(100)} />
            <Box sx={{ backgroundColor: theme.palette.background.default, display: 'flex', flexDirection: 'column', position: 'absolute', top: '-18px', left: '48%', width: '40px', height: '40px', border: `1px solid ${theme.palette.primary.main}`, borderRadius: '50%', justifyContent: 'center', justifyItems: 'center' }}>
                <Typography align='center' alignItems='center' sx={{ color: theme.palette.text.default }}>{data.progress}%</Typography>
            </Box>
        </Box>

    )
}

export default CourseProgressBar