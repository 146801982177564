import React, { useState, useEffect, useRef } from 'react';
import '../CSS/root.css';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import { recordingSocket, socket } from '../../../config/SocketApi';
import { useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react';
import Peer from 'simple-peer';
import DrawingBoardShareScreen from './DrawingBoardShareScreen';
export default function ShareScreen() {
    const theme = useTheme()
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const { room_id } = useParams();
    const [stream, setStream] = useState();
    const [tutorStream, setTutorStream] = useState();
    // const [socketState, setSocketState] = useState('stable')
    const [topCorner, setTopConrner] = useState();
    const [preTopCorner, setPreTopCorner] = useState()
    const [screenSharingDimensions, setScreenSharingDimensions] = useState()
    const [rectangleDimensions, setRectangleDimensions] = useState();
    const [preFullScreenDimensions, setPreFullScreenDimensions] = useState();
    const [mouseLocation, setMouseLocation] = useState();
    const [sessionEnded, setSessionEnded] = useState(false);
    const [rectangleSelected, setRectangleSelected] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [resizeStarted, setResizeStarted] = useState(false);
    const [sharingStarted, setSharingStarted] = useState(false);
    const selectedRectangle = useRef();
    const dragIconRef = useRef();
    const startButtonRef = useRef();
    const handlerTopRightRef = useRef();
    const handlerTopLeftRef = useRef();
    const handlerBottomLeftRef = useRef();
    const handlerBottomRightRef = useRef();
    const videoRef = useRef(null);
    const tutorSreamRef = useRef();
    const canvasRef = useRef(null);
    const croppedVideoRef = useRef(null);
    const tutorPeerRefs = useRef([]);
    const serverPeerRef = useRef();
    const senderStudentsPeerRefs = useRef([]);
    const canvasScal = 2;
    const scalFactor = 2;
    const closeShareWindow = useRef(false)
    const sessionEndedRef = useRef(false)
    const serverHandlerPeerRef = useRef();
    const animationFrameRef = useRef();


    const handleMouseDown = (e) => {
        if (!rectangleSelected || (rectangleSelected && rectangleDimensions?.w === 0)) {
            setTopConrner({ x: e.clientX, y: e.clientY });
        }
    }
    const handleMouseMove = (e) => {
        if (!rectangleSelected || (rectangleSelected && rectangleDimensions?.w === 0)) {
            setMouseLocation({ x: e.clientX, y: e.clientY });

        }
    }
    const handleMouseUp = (e) => {
        if (!rectangleSelected && selectedRectangle.current.style.width !== "0px" && selectedRectangle.current.style.height !== "0px" && selectedRectangle.current.style.width !== "" && selectedRectangle.current.style.height !== "") {
            setRectangleSelected(true);
            setRectangleDimensions({ w: selectedRectangle.current.clientWidth, h: selectedRectangle.current.clientHeight });
        }
    }
    const handleDragScreen = (e) => {
        if (e.clientX && e.clientY && !fullScreen) {
            if (e.clientX - dragIconRef.current.offsetLeft - 18 > 0 && e.clientX + rectangleDimensions.w - dragIconRef.current.offsetLeft - 12 < window.innerWidth) {
                selectedRectangle.current.style.left = e.clientX - dragIconRef.current.offsetLeft - 18 + 'px';
            }
            if (e.clientY - dragIconRef.current.offsetTop - 18 > 0 && e.clientY - dragIconRef.current.offsetTop + rectangleDimensions.h - 12 < window.innerHeight) {
                selectedRectangle.current.style.top = e.clientY - 18 + 'px';
            }
        }
    }

    const handleFullScreen = () => {
        if (fullScreen) {
            selectedRectangle.current.style.top = topCorner.y + 'px';
            selectedRectangle.current.style.left = topCorner.x + 'px';
            selectedRectangle.current.style.width = preFullScreenDimensions.w + 'px';
            selectedRectangle.current.style.height = preFullScreenDimensions.h + 'px';
            setRectangleDimensions({ w: preFullScreenDimensions.w, h: preFullScreenDimensions.h })
            setTopConrner(preTopCorner)
            setFullScreen(false)
            return;
        }
        selectedRectangle.current.style.top = 32 + 'px';
        selectedRectangle.current.style.left = 5 + 'px';
        selectedRectangle.current.style.width = window.innerWidth - 10 + 'px';
        selectedRectangle.current.style.height = window.innerHeight - 37 + 'px';
        setPreTopCorner(topCorner)
        setPreFullScreenDimensions({ w: rectangleDimensions.w, h: rectangleDimensions.h })
        setRectangleDimensions({ w: window.innerWidth - 10, h: window.innerHeight - 37 })
        setFullScreen(true)
    }
    const handleTopLeftResize = (e) => {
        e.preventDefault();
        if (e.clientX && e.clientY) {
            if ((topCorner.x + rectangleDimensions.w - e.clientX) < 640) return
            selectedRectangle.current.style.top = topCorner.y - (topCorner.x + rectangleDimensions.w - e.clientX) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + rectangleDimensions.h + 'px';
            selectedRectangle.current.style.left = e.clientX + 'px';
            selectedRectangle.current.style.width = topCorner.x + rectangleDimensions.w - e.clientX + 'px';
            selectedRectangle.current.style.height = (topCorner.x + rectangleDimensions.w - e.clientX) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + 'px' //
            handleAcceptedRegion(topCorner.x + rectangleDimensions.w - e.clientX)
        }
    }
    const handleTopRightResize = (e) => {
        if (e.clientX && e.clientY) {
            if ((rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX)) < 640) return
            selectedRectangle.current.style.top = topCorner.y - (rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX)) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + rectangleDimensions.h + 'px';
            selectedRectangle.current.style.width = rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX) + 'px';
            selectedRectangle.current.style.height = (rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX)) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + 'px'
            handleAcceptedRegion(rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX))
        }
    }
    const handleBottomRightResize = (e) => {
        if (e.clientX && e.clientY) {
            if ((rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX)) < 640) return
            selectedRectangle.current.style.width = rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX) + 'px';
            selectedRectangle.current.style.height = (rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX)) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + 'px'; //rectangleDimensions.h - (topCorner.y + rectangleDimensions.h - e.clientY) + 'px';
            handleAcceptedRegion(rectangleDimensions.w - (topCorner.x + rectangleDimensions.w - e.clientX))
        }
    }
    const handleBottomLeftResize = (e) => {
        if (e.clientX && e.clientY) {
            if ((rectangleDimensions.w - (e.clientX - topCorner.x)) < 640) return
            selectedRectangle.current.style.left = e.clientX + 'px';
            selectedRectangle.current.style.width = rectangleDimensions.w - (e.clientX - topCorner.x) + 'px';
            selectedRectangle.current.style.height = (rectangleDimensions.w - (e.clientX - topCorner.x)) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + 'px'; //rectangleDimensions.h - (topCorner.y + rectangleDimensions.h - e.clientY) + 'px';
            handleAcceptedRegion(rectangleDimensions.w - (e.clientX - topCorner.x))
        }
    }
    const handleResizeEnd = (e) => {//this function for resizing the window

        setTopConrner({ x: selectedRectangle.current.offsetLeft, y: selectedRectangle.current.offsetTop })
        setRectangleDimensions({ w: selectedRectangle.current.clientWidth, h: selectedRectangle.current.clientHeight });
        // setClickThrough()
    }
    const handlendDrag = (e) => {
        setTopConrner({ x: selectedRectangle.current.offsetLeft, y: selectedRectangle.current.offsetTop })
        setRectangleDimensions({ w: selectedRectangle.current.clientWidth, h: selectedRectangle.current.clientHeight });
        socket.emit("canvas_data", { w: selectedRectangle.current.clientWidth, h: selectedRectangle.current.clientWidth });
    }
    const handleCLoseSharingSocket = () => {
        socket.emit("kill-share-screen-stream-server-handler", { isClosed: true })
        socket.emit("white-board-handler-reset-screen-dimensions", {})
        selectedRectangle.current.style.width = "0px";
        selectedRectangle.current.style.height = "0px";
        setSharingStarted(false)
        setScreenSharingDimensions(false)
        if (tutorStream) {
            tutorStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
    }
    const handleOpenTheWindow = () => {
        setSharingStarted(true);
    }

    useEffect(() => {
        if (sharingStarted && !sessionEnded) {
            socket.emit("screen-dimentions", { ...topCorner, width: rectangleDimensions.w, height: rectangleDimensions.h })
            socket.emit("kill-share-screen-stream-server-handler", { close: false })
            if (tutorStream) {
                tutorStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (stream) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)

            handleScreenSharingStream({ ...topCorner, width: rectangleDimensions.w, height: rectangleDimensions.h });
            setScreenSharingDimensions({ x: topCorner.x, y: topCorner.y, width: rectangleDimensions.w, height: rectangleDimensions.h, fullWidth: window.innerWidth, fullHeight: window.innerHeight, PR: window.devicePixelRatio, screenSharing: true, dir: theme.direction })
        }
    }, [sharingStarted, topCorner, rectangleDimensions, fullScreen, sessionEnded])


    const setClickable = () => {
        if (!sharingStarted) return;
        window.electron.setClickable();
    }
    const setClickThrough = () => {
        if (resizeStarted || !sharingStarted) return;
        window.electron.setClickThrough();
    }
    const handleAcceptedRegion = (w) => {
        if (w > 900) {

            if (startButtonRef.current) startButtonRef.current.style.border = '1px solid green';
            if (startButtonRef.current) startButtonRef.current.style.backgroundColor = 'green';
            selectedRectangle.current.style.border = '2px solid green';
            handlerTopRightRef.current.style.backgroundColor = 'green';
            handlerTopRightRef.current.style.border = '2px solid green';
            handlerBottomRightRef.current.style.backgroundColor = 'green';
            handlerBottomRightRef.current.style.border = '2px solid green';
            handlerTopLeftRef.current.style.backgroundColor = 'green';
            handlerTopLeftRef.current.style.border = '2px solid green';
            handlerBottomLeftRef.current.style.backgroundColor = 'green';
            handlerBottomLeftRef.current.style.border = '2px solid green';
        } else {

            if (startButtonRef.current) startButtonRef.current.style.border = '1px solid rgb(255, 82, 47)';
            if (startButtonRef.current) startButtonRef.current.style.backgroundColor = 'rgb(255, 82, 47)';
            selectedRectangle.current.style.border = '2px solid rgb(255, 82, 47)';
            handlerTopRightRef.current.style.backgroundColor = 'rgb(255, 82, 47)';
            handlerTopRightRef.current.style.border = '2px solid rgb(255, 82, 47)';
            handlerBottomRightRef.current.style.backgroundColor = 'rgb(255, 82, 47)';
            handlerBottomRightRef.current.style.border = '2px solid rgb(255, 82, 47)';
            handlerTopLeftRef.current.style.backgroundColor = 'rgb(255, 82, 47)';
            handlerTopLeftRef.current.style.border = '2px solid rgb(255, 82, 47)';
            handlerBottomLeftRef.current.style.backgroundColor = 'rgb(255, 82, 47)';
            handlerBottomLeftRef.current.style.border = '2px solid rgb(255, 82, 47)';
        }
    }

    useEffect(() => {
        if (!rectangleSelected) {
            selectedRectangle.current.style.display = 'none';
        }
        if (topCorner && mouseLocation && !rectangleSelected) {
            if (mouseLocation.x - topCorner.x > 0 && mouseLocation.y - topCorner.y > 0) {
                if (!rectangleDimensions || rectangleDimensions?.w === 0) {
                    selectedRectangle.current.style.display = 'inherit';
                    selectedRectangle.current.style.top = topCorner.y + 'px';
                    selectedRectangle.current.style.left = topCorner.x + 'px';
                    selectedRectangle.current.style.width = mouseLocation.x - topCorner.x + 'px';
                    selectedRectangle.current.style.height = (mouseLocation.x - topCorner.x) * ((window.innerHeight - 150) / (window.innerWidth * 20 / 25)) + 'px' //mouseLocation.y - topCorner.y + 'px';
                    handleAcceptedRegion(mouseLocation.x - topCorner.x)
                } else {
                    if (!rectangleSelected || rectangleDimensions?.w === 0) {
                        selectedRectangle.current.style.display = 'none';
                    }
                }
            } else {
                if (rectangleDimensions || rectangleDimensions?.w === 0) {
                    selectedRectangle.current.display = 'none';
                }
            }
        }
    }, [topCorner, mouseLocation, rectangleSelected, rectangleDimensions])

    useEffect(() => {
        if (user) {
            socket.connect().auth = { token: user?.token, room_id: room_id, user_type: user?.user_type, user_id: 'share_screen_socket', user_name: user?.full_name }
            recordingSocket.connect().auth = { token: user?.token, room_id: room_id, user_type: user?.user_type, user_id: 'share_screen_socket', user_name: user?.full_name }
        }

    }, [user])
    useEffect(() => {

        socket.on("screen-sharing-student-signal", (data) => {
            const signal = data.data;
            const studentSocketId = data.studentSocketId;
            if (tutorPeerRefs.current.length) {
                const studentPeer = tutorPeerRefs.current.find(p => p.studentSocketId === studentSocketId)
                if (studentPeer) {
                    studentPeer?.peer.signal(signal);
                }
            }
        })
        socket.on("server-handler-share-screen-signal", (data) => {
            const signal = data.data;
            serverHandlerPeerRef.current.signal(signal)
        })

        socket.on("student-disconnect", (socket_id) => {
            const disconnectdStudent = tutorPeerRefs.current.find(p => p.studentSocketId === socket_id);
            if (disconnectdStudent) {
                disconnectdStudent?.peer.destroy();
                tutorPeerRefs.current = tutorPeerRefs.current.filter(p => p.studentSocketId !== socket_id);
            }
            const disconnectdSenderStudent = senderStudentsPeerRefs.current.find(p => p.sender_student_socket_id === socket_id);
            if (disconnectdSenderStudent) {
                disconnectdSenderStudent?.peer.destroy();
                senderStudentsPeerRefs.current = senderStudentsPeerRefs.current.filter(p => p.sender_student_socket_id !== socket_id);
            }
        })
        socket.once("server-share-screen-student-signal", (data) => {
            const signal = data.data;
            if (serverPeerRef.current) {
                serverPeerRef.current?.signal(signal);
            }

        })
        socket.on("share-screen-server-end-session", () => {
            setSessionEnded(true);
            sessionEndedRef.current = true;
        })
        socket.on("meeting-started-end-sharing", () => {
            closeShareWindow.current = true;
            setRectangleSelected(false);
            setRectangleDimensions(null);
            setTopConrner(null);
            setFullScreen(false)
            setSharingStarted(false);
            handleCLoseSharingSocket();
        })

        // socket.on("connect_error", error => {
        //     setSocketState('errored')
        // })
        // socket.on("connect", attempts => {
        //     // if(socketState)
        //     setSocketState('reconnected')
        // })
        return () => {
            socket.off("connect")
            socket.off("meeting-started-end-sharing")
            socket.off("server-handler-share-screen-signal")
            socket.off("server-handler-white-board-signal")
            socket.off("server-share-screen-student-signal")
            socket.off("share-screen-server-end-session")
            socket.off("screen-sharing-student-signal")
            socket.off("student-disconnect")
            socket.off("connect_error");

            if (tutorPeerRefs.current.length) tutorPeerRefs.current.map(p => p?.peer.destroy());
            tutorPeerRefs.current = [];
            if (tutorStream) {
                tutorStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (stream) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
            setTutorStream(null);
            tutorSreamRef.current = null;
        }
    }, [])
    useEffect(() => {
        socket.on("student-connected-share-screen", (socket_id) => {
            if (screenSharingDimensions) {
                addConnectedStudent(socket_id);
            }

        })
        return () => {
            socket.off("student-connected-share-screen")
        }
    }, [screenSharingDimensions])

    useEffect(() => {
        if (sessionEnded) {
            if (tutorPeerRefs.current.length) tutorPeerRefs.current.map(p => p?.peer.destroy());
            tutorPeerRefs.current = [];
            if (tutorStream) {
                tutorStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (stream) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
            setTutorStream(null);
            setStream(null)
            tutorSreamRef.current = null;

        }
    }, [sessionEnded])


    const handleScreenSharingStream = async (dimensions) => {
        // setSocketState('stable')
        const sourceId = await window.electron.getSourceId();
        if (sourceId) {
            try {
                const str = await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: {
                        mandatory: {
                            chromeMediaSource: 'desktop',
                            chromeMediaSourceId: sourceId,

                        }
                    }
                });

                setStream(str)

                // setStream(processStream(str, dimensions.x, dimensions.y, dimensions.width, dimensions.height));
            } catch (e) {
                console.log("the stream error is ", e)
            }
        } else {
            console.log("could'nt get the source id")
        }
    }
    // function processStream(stream, x, y, width, height) {
    //     // console.log("the data is ", width, height)
    //     const video = document.createElement('video');
    //     video.style.display = 'none';
    //     video.style.width = width + 'px';
    //     video.style.height = height + 'px';
    //     video.width = width;
    //     video.height = height;
    //     video.srcObject = stream;
    //     const canvas = document.createElement('canvas');
    //     canvas.style.display = 'none';
    //     const context = canvas.getContext('2d');

    //     // Set canvas size to the desired region size
    //     const ratio = window.devicePixelRatio || 1;
    //     canvas.width = width * ratio * scalFactor;
    //     canvas.height = height * ratio * scalFactor;
    //     canvas.style.width = width + 'px';
    //     canvas.style.height = height + 'px';
    //     context.imageSmoothingEnabled = false
    //     context.scale(ratio * scalFactor, ratio * scalFactor);
    //     const capturedStream = canvas.captureStream(10);
    //     video.onloadedmetadata = () => {
    //         video.play();
    //         animationFrameRef.current = requestAnimationFrame(renderFrame);
    //     };
    //     let lastTime = 0;
    //     const interval = 1000 / 10;
    //     function renderFrame(now) {
    //         // Draw the specified region of the video onto the canvas
    //         // const videoTrack = capturedStream.getVideoTracks()[0];
    //         // const trackSettings = videoTrack.getSettings();
    //         // console.log(trackSettings.width, trackSettings.height);
    //         const deltaTime = now - lastTime;
    //         console.log()
    //         if (deltaTime > interval) {
    //             context.clearRect(0, 0, canvas.width, canvas.height);
    //             context.drawImage(video, x, y, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
    //             lastTime = now - (deltaTime % interval);
    //         }
    //         animationFrameRef.current = requestAnimationFrame(renderFrame);
    //     }

    //     // Return the canvas stream
    //     return capturedStream; // Adjust the frame rate as needed
    // }
    const createServerHandlerPeer = (serverHandler, stream) => {
        const newPeer = new Peer({
            initiator: true,
            trickle: true,
            stream,
            config: {
                iceServers: [{
                    urls: 'stun:5.134.255.233:3478'
                }
                    ,
                {
                    urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                },
                {
                    urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                }
                ]
            }
        });
        newPeer.on("signal", (data) => {
            socket.emit("share-screen-signal-to-server-handler", { signal: data, tutor_id: socket.id, handlerSocketId: serverHandler.socket_id, whiteBoardDimensions: screenSharingDimensions });
        });

        newPeer.on('error', (err) => {
            // createServerHandlerPeer(serverHandler, stream)
            console.log("the peer error is ", err)
        })
        serverHandlerPeerRef.current = newPeer
    }
    const createPeer = (student, stream) => {
        const newPeer = new Peer({
            initiator: true,
            trickle: true,
            stream,
            config: {
                iceServers: [{
                    urls: 'stun:5.134.255.233:3478'
                }
                    ,
                {
                    urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                },
                {
                    urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                }
                ]
            }

        });
        newPeer.on("signal", (data) => {
            socket.emit("screen-sharing-totur-signal", { signal: data, tutor_id: socket.id, studentSocketId: student.socket_id, whiteBoardDimensions: screenSharingDimensions });
        });

        newPeer.on('error', (err) => {
            // createPeer(student, stream)
            console.log("the peer error is ", err)
        })
        if (tutorPeerRefs.current.length) {
            const studentAdded = tutorPeerRefs.current.find(p => p.studecanvasScalntSocketId === student.socket_id)
            if (!studentAdded) { tutorPeerRefs.current.push({ peer: newPeer, studentSocketId: student.socket_id }); } else { studentAdded.peer = newPeer }
        } else {
            tutorPeerRefs.current.push({ peer: newPeer, studentSocketId: student.socket_id });
        }
    }

    const addConnectedStudent = (socket_id) => {

        const newPeer = new Peer({
            initiator: true,
            trickle: true,
            stream: (tutorStream && tutorStream.active) ? tutorStream : tutorSreamRef.current,
            config: {
                iceServers: [{
                    urls: 'stun:5.134.255.233:3478'
                }
                    ,
                {
                    urls: 'turn:5.134.255.233:3478?transport=udp', // UDP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                },
                {
                    urls: 'turn:5.134.255.233:3478?transport=tcp', // TCP configuration
                    username: 'turnTorbet',
                    credential: 'Torbet@2023'
                }
                ]
            }
        });
        newPeer.on("signal", (data) => {
            socket.emit("screen-sharing-totur-signal", { signal: data, tutor_id: socket.id, studentSocketId: socket_id, whiteBoardDimensions: screenSharingDimensions });
        });
        newPeer.on('error', (err) => {
            // addConnectedStudent(socket_id)
            console.log("the peer error is ", err)
        })
        tutorPeerRefs.current.push({ peer: newPeer, studentSocketId: socket_id });

    }

    useEffect(() => {
        if (stream) {
            const allTracksEnded = stream.getTracks().every(track => track.readyState === 'ended');
            if (!allTracksEnded) {
                if (tutorPeerRefs.current.length) tutorPeerRefs.current.map(p => p?.peer.destroy());
                tutorPeerRefs.current = []
                socket.emit("get-server-handler", serverHandler => {
                    if (serverHandler) {
                        createServerHandlerPeer(serverHandler, stream);
                        socket.emit("get-connected-students", students => {
                            if (students.length) {
                                students.map(student => {
                                    if (student.user_type === 0)
                                        createPeer(student, stream);
                                })
                            }
                        })
                    }
                })
                tutorSreamRef.current = stream;
                setTutorStream(stream);
            }

        }
    }, [stream]);

    useEffect(() => {
        if (screenSharingDimensions) {
            canvasRef.current.width = screenSharingDimensions.width * canvasScal
            canvasRef.current.height = screenSharingDimensions.height * canvasScal
        }
    }, [screenSharingDimensions]);

    return (
        <div className='sharing-select-window-container'
            onMouseEnter={(e) => {
                setClickThrough(e);
            }}
            onMouseDown={(e) => handleMouseDown(e)}
            onMouseUp={(e) => handleMouseUp(e)}
            onMouseMove={(e) => handleMouseMove(e)}>
            {screenSharingDimensions && <DrawingBoardShareScreen dimensions={screenSharingDimensions} />}
            <video ref={videoRef} autoPlay muted style={{ display: 'none' }} />
            <video ref={croppedVideoRef} autoPlay muted style={{ display: 'none' }} />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <div className='selected-rectangle' ref={selectedRectangle}>
                {!sharingStarted && <div className='start-share-screen-button' style={{ display: user?.user_type !== 0 ? 'block' : 'none' }} onClick={(e) => { handleOpenTheWindow(); }} onMouseLeave={() => setClickThrough()} ref={startButtonRef}>Start</div>}
                <ButtonGroup className='selected-rectangle-button-group' variant="contained" aria-label="outlined primary button group" onMouseEnter={() => setClickable()} onMouseLeave={() => setClickThrough()}>
                    <IconButton color="error" className='selected-rectangle-close-icon' onClick={(e) => {

                        closeShareWindow.current = true;
                        setRectangleSelected(false);
                        setRectangleDimensions(null);
                        setTopConrner(null);
                        setFullScreen(false)
                        setSharingStarted(false);
                        handleCLoseSharingSocket();
                    }}>
                        <CloseIcon className='panel-icon-button' />
                    </IconButton>
                    <IconButton ref={dragIconRef} color="primary" draggable={!fullScreen} className='selected-rectangle-drag-icon' onDrag={(e) => {
                        handleDragScreen(e);
                    }} onDragEnd={() => { setResizeStarted(false); handlendDrag(); setClickThrough() }} onDragStart={() => setResizeStarted(true)}>
                        <OpenWithIcon className='panel-icon-button' />
                    </IconButton>
                    <IconButton color="primary" onClick={handleFullScreen}>
                        {fullScreen ? <FullscreenExitIcon className='panel-icon-button' /> : <FullscreenIcon className='panel-icon-button' />}
                    </IconButton>
                </ButtonGroup>
                <span className='top-left-resize-handler'
                    onDragStart={() => setResizeStarted(true)}
                    draggable={!fullScreen}
                    onDrag={(e) => handleTopLeftResize(e)}
                    onDragEnd={(e) => { setResizeStarted(false); handleResizeEnd(e); setClickThrough() }}
                    onMouseEnter={() => setClickable()}
                    onMouseLeave={() => setClickThrough()}
                    ref={handlerTopLeftRef}></span>
                <span className='bottom-left-resize-handler'
                    onDragStart={() => setResizeStarted(true)}
                    draggable={!fullScreen}
                    onDrag={(e) => handleBottomLeftResize(e)}
                    onDragEnd={(e) => { setResizeStarted(false); handleResizeEnd(e); setClickThrough() }}
                    onMouseEnter={() => setClickable()}
                    onMouseLeave={() => setClickThrough()}
                    ref={handlerBottomLeftRef}></span>
                <span className='top-right-resize-handler'
                    onDragStart={() => setResizeStarted(true)}
                    draggable={!fullScreen}
                    onDrag={(e) => handleTopRightResize(e)}
                    onDragEnd={(e) => { setResizeStarted(false); handleResizeEnd(e); setClickThrough() }}
                    onMouseEnter={() => setClickable()}
                    onMouseLeave={() => setClickThrough()}
                    ref={handlerTopRightRef}></span>
                <span className='bottom-right-resize-handler'
                    onDragStart={() => setResizeStarted(true)}
                    draggable={!fullScreen}
                    onDrag={(e) => handleBottomRightResize(e)}
                    onDragEnd={(e) => { setResizeStarted(false); handleResizeEnd(e); setClickThrough() }}
                    onMouseEnter={() => setClickable()}
                    onMouseLeave={() => setClickThrough()}
                    ref={handlerBottomRightRef}></span>
            </div>
        </div>
    );
}

