import React from 'react'
import { BottomNavigation, Box, CardMedia, styled, Typography, useTheme } from '@mui/material';
import { YMaps, Map, Placemark } from "react-yandex-maps";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useTranslation } from 'react-i18next';

function Footer() {
    const theme = useTheme();
    const { t } = useTranslation();
    const FooterBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        marginTop: '50px'
    })

    const LogoBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40vw',
        margin: 'auto',
        gap: '10px',
        height: '75px'
    })
    const MapBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '150px',
        marginTop: '50px'

    })
    const MapContainer = styled(Box)({
        width: '350px',
        height: '150px',
        borderRadius: "10px",
        overflow: "hidden"


    })
    const DividerBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        justifyContent: 'space-around',
        width: '10vw',
        margin: 'auto',
        gap: '20px'
    })

    const LineBox = styled(Box)({
        height: '2px',
        backgroundColor: theme.palette.background.default,
    })
    const LinksBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '150px',
        width: 'calc(50% - 125px)',

    })
    const LinksInnerBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        gap: '20px',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.background.default}`
    })
    const SocialBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '150px',
        width: 'calc(50% - 125px)',


    })
    const SocialInnerBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        gap: '10px',
        width: '100%',
        borderTop: `1px solid ${theme.palette.background.default}`
    })
    const PoweredBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '125px',
        gap: '10px',
        width: '100%',
        marginTop: '20px',
        background: 'linear-gradient(to left, rgba(209, 77, 205, 0.25), rgba(59, 126, 197, 0.25))'

    })
    const TypographyLink = styled(Typography)({
        color: theme.palette.text.secondary,
        cursor: 'pointer'

    })
    return (
        <BottomNavigation sx={{ height: '375px', width: '100%', backgroundColor: theme.palette.background.gray, marginTop: '120px', }}>
            <FooterBox>
                <LogoBox>
                    <DividerBox sx={{ direction: 'rtl' }}>
                        <LineBox sx={{ width: '50%' }} />
                        <LineBox sx={{ width: '100%' }} />
                        <LineBox sx={{ width: '75%' }} />
                    </DividerBox>
                    <CardMedia component="img"
                        image={`${process.env.PUBLIC_URL}/assets/Footer_Logo.svg`}
                        sx={{ width: '75px' }}
                    />
                    <DividerBox >
                        <LineBox sx={{ width: '50%' }} />
                        <LineBox sx={{ width: '100%' }} />
                        <LineBox sx={{ width: '75%' }} />
                    </DividerBox>
                </LogoBox>
                <MapBox>
                    <LinksBox>
                        <LinksInnerBox>
                            <TypographyLink>{t("privacypolicy")}</TypographyLink>
                            <TypographyLink>{t("support")}</TypographyLink>
                            <TypographyLink>{t("contactus")}</TypographyLink>
                        </LinksInnerBox>
                    </LinksBox>
                    <MapContainer>
                        <YMaps key={"ar_SY"} query={{ lang: "ar_SY" }}>
                            <Map
                                defaultState={{
                                    center: [33.498983572135636, 36.24612574133249],
                                    zoom: 15,
                                    type: "yandex#map",
                                }}
                                width='350px'
                                height='150px'
                            >

                                <Placemark
                                    geometry={[
                                        33.498983572135636, 36.24612574133249
                                    ]}
                                    properties={{
                                        iconCaption: `Torbet`,
                                    }}
                                    options={{
                                        iconColor: theme.palette.primary.main,
                                        preset: "islands#dotIcon",
                                    }}

                                />
                            </Map>
                        </YMaps>
                    </MapContainer>

                    <SocialBox >

                        <SocialInnerBox>
                            <LinkedInIcon sx={{ color: theme.palette.icon.main, cursor: 'pointer' }} />
                            <InstagramIcon sx={{ color: theme.palette.icon.main, cursor: 'pointer' }} />
                            <FacebookIcon sx={{ color: theme.palette.icon.main, cursor: 'pointer' }} />
                        </SocialInnerBox>
                    </SocialBox>
                </MapBox>
                <PoweredBox>
                    <Typography sx={{ color: theme.palette.primary.main }}>{t("poweredby")}</Typography>
                    {/* <CardMedia component="img"
                        image="./assets/poweredBy.svg"
                        sx={{ width: '300px' }}
                    /> */}
                    <img src={`${process.env.PUBLIC_URL}/assets/poweredBy.svg`} style={{ width: '300px' }} />
                </PoweredBox>
            </FooterBox>
        </BottomNavigation>
    )
}

export default Footer