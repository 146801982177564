import { createSlice } from '@reduxjs/toolkit';
import { socket } from '../../config/SocketApi';

const lang = JSON.parse(localStorage.getItem('lang'));
const initialState = {
    tabs: [{ id: 1, title: lang?.lang === 'en' ? "Board " + 1 : "نافذة " + 1, active: true, closable: false }],
    panels: [{ id: 1, active: true, path: false }]
};


const tabsSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        reset: (state, { payload }) => {
            state.tabs = [{ id: 1, title: lang?.lang === 'en' ? "Board " + 1 : "نافذة " + 1, active: true, closable: false }];
            state.panels = [{ id: 1, active: true, path: false }]
        },
        addTab: (state, { payload }) => {
            state.tabs = [...state.tabs, payload.tab];
        },
        setActiveTab: (state, { payload }) => {
            //state.tabs.map(tab => tab.active = false)
            const activeTab = state.tabs.find(tab => tab.id === payload.id)
            if (activeTab) {
                state.tabs.map(tab => {
                    if (tab.id === payload.id) {
                        tab.active = true
                    } else {
                        tab.active = false
                    }
                })
            }

        },
        removeTab: (state, { payload }) => {
            state.tabs = state.tabs.filter(tab => tab.id !== payload.id)
        },
        addPanel: (state, { payload }) => {
            state.panels = [...state.panels, payload.panel];
        },
        removePanel: (state, { payload }) => {
            state.panels = state.panels.filter(panel => panel.id !== payload.id)
        },
        setActivePanel: (state, { payload }) => {
            //state.panels.map(panel => panel.active = false)
            const activePanel = state.panels.find(panel => panel.id === payload.id)
            if (activePanel?.path) socket.emit('current-path', { path: activePanel.path, id: payload.id })
            if (activePanel) {
                state.panels.map(panel => {
                    if (panel.id === payload.id) {
                        panel.active = true
                    } else {
                        panel.active = false
                    }
                })
            }
        },
        addPath: (state, { payload }) => {
            const activePanel = state.panels.find(panel => panel.id === payload.id)
            socket.emit('current-path', { path: payload.path, id: payload.id })
            if (activePanel) activePanel.path = payload.path;
        },
    },
});

export const { addTab, removeTab, setActiveTab, addPanel, removePanel, setActivePanel, addPath, reset } =
    tabsSlice.actions;

export default tabsSlice.reducer;
