/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Backdrop, Box, CardMedia, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close';
import LoginIcon from '@mui/icons-material/Login';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API, { BaseUrl } from '../../../config/API';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default
}));
function Session({ data, teacherUserId }) {
    console.log("the session data is ", data, teacherUserId)
    const theme = useTheme();
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(false)
    const [downloadError, setDownloadError] = useState(false)
    const [sessionVideos, setSessionVideos] = useState([]);
    const [videoDialogOpen,setVideoDialogOpen] = useState(false);
    const [error, setError] = useState(false)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleNavigation = () => {
        // breadCrumbAdder({ translatable: false, title: data.title, route: `/session/${data.id}` })
        // navigate(`/session/${data.id}`)
    }
    const getReadableSessionStatus = (status) => {
        switch (status) {
            case "0":
                return [t("notStarted"), 'orange']
            case "1":
                return [t("started"), 'green']
            case "2":
                return [t("ended"), 'gray']
            default:
                return [t("ended"), 'gray']

        }
    }
    const handleDownloadSessions = ()=>{
        const sessionId = data.id;
        console.log("the user token is ", user.token)
        const body = JSON.stringify({
            session_id: sessionId,
        })
        API.post(`session/final_videos`, body, {
            headers: {
                Authorization: user.token,
                "Content-Type": 'application/json'
            }
        }).then(data => {
            if (data?.status === 200) {
                console.log("the data is ", data.data.data)
                if(data?.data?.data.length === 0){
                    setDownloadError(true);
                    return
                }
                setSessionVideos(data?.data?.data);
                setVideoDialogOpen(true)
                // navigate(`/class_room/${sessionId}/${teacherUserId}`)
            } else {
                setError(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log("the error is ", err);
            setLoading(false)
            setError(true)
        })
        // recordingSocket.emit('get-session-recordings',{session_id:data.id})
        // //     ,(data)=>{
        // //     console.log("the data is ",data)
        // // })
    }
    const handleDownloadSessionsVideo = (videoUrl)=>{
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = `${data.title}.mp4`; // You can set the file name here
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Remove the anchor element from the document
        document.body.removeChild(link);
    }
    const handleOpenVirtualClassRoom = (sessionId) => {
        setLoading(true)
        const body = JSON.stringify({
            session_id: sessionId,
            new_status: 1
        })
        API.post(`session/status`, body, {
            headers: {
                Authorization: user.token,
                "Content-Type": 'application/json'
            }
        }).then(data => {
            if (data?.status === 200) {
                navigate(`/class_room/${sessionId}/${teacherUserId}`)
            } else {
                setError(true)
            }
            setLoading(false)
        }).catch(err => {
            console.log("the error is ", err);
            setLoading(false)
            setError(true)
        })
    }
    return (
        <AccordionDetails>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CardMedia component="img"
                    image="./assets/loading.gif"
                    sx={{ width: '8rem' }}
                />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={error}
            >
                <Box sx={{ margin: 'auto', position: 'relative', padding: '2rem', border: `1px solid ${theme.palette.text.primary}`, borderRadius: '5px' }}>
                    <Typography variant='h5' color={theme.palette.danger.main} align='center' margin={2}>{t("error500")}</Typography>
                    <IconButton sx={{ color: theme.palette.danger.main, position: 'absolute', top: 0, left: 0 }} onClick={() => setError(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={downloadError}
            >
                <Box sx={{ margin: 'auto', position: 'relative', padding: '2rem', border: `1px solid ${theme.palette.text.primary}`, borderRadius: '5px' }}>
                    <Typography variant='h5' color={theme.palette.danger.main} align='center' margin={2}>{t("underProcess")}</Typography>
                    <IconButton sx={{ color: theme.palette.danger.main, position: 'absolute', top: 0, left: 0 }} onClick={() => setDownloadError(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={videoDialogOpen && sessionVideos.length}
            >
                <Box sx={{gap:'1rem', margin: 'auto', position: 'relative', padding: '2rem', border: `1px solid ${theme.palette.text.primary}`, borderRadius: '5px', display:'flex', flexDirection:'column', backgroundColor:theme.palette.background.default }}>
                    {sessionVideos.map((s,index)=>(
                        <Box key={index} sx={{display:'flex', flexDirection:'row', gap:'1rem', justifyContent:'center', alignItems:'center'}}>
                            <video controls  width={300} src={`${BaseUrl}${s.video_path}`}/>
                            <Typography sx={{fontWeight:'bold'}} color={theme.palette.text.primary}>{`${t("part")}_${s.session_part} ${s.session_size}`}</Typography>
                            <IconButton onClick={()=>handleDownloadSessionsVideo(`${BaseUrl}${s.video_path}`)}><DownloadIcon  sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                    /></IconButton>
                        </Box>
                    ))}
                    <IconButton sx={{ color: theme.palette.danger.main, position: 'absolute', top: 0, left: 0 }} onClick={() => setVideoDialogOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

            </Backdrop>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', justifyItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}>
                    <Typography sx={{ color: theme.palette.text.default }}>{data?.session_number || '#'}.</Typography>
                    <Typography sx={{ cursor: 'pointer' }} onClick={() => handleNavigation()}>{data.title}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '2px' }}>
                        <Box sx={{ width: '8px', height: '8px', backgroundColor: getReadableSessionStatus(data.s_status)[1], borderRadius: '50%' }} />
                        <Typography>{getReadableSessionStatus(data.s_status)[0]}</Typography>
                    </Box>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '20px' }}>
                    <Typography>{data.length}</Typography>
                    {data.s_status === "1"  && <LoginIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => navigate(`/class_room/${data.id}/${teacherUserId}`)} />}
                    {/* {data.s_status === "1" && teacherUserId !== user.id && <LoginIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} />} */}
                    {data.s_status === "2" && <IconButton onClick={()=>handleDownloadSessions()}><DownloadIcon  sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                    // onClick={() => navigate(`/class_room/${data.id}/${courseId}`)} 
                    /></IconButton>}
                    {data.s_status === "0" && teacherUserId === user.id && <HourglassEmptyIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                        onClick={() => handleOpenVirtualClassRoom(data.id)}
                    />}
                    {data.s_status === "0" && teacherUserId !== user.id && <HourglassEmptyIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                    // onClick={() => handleOpenVirtualClassRoom(data.id,courseId)} 
                    />}
                    {/* <QuizIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} />
                    <ArticleIcon sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} /> */}
                </Box>
            </Box>
        </AccordionDetails>
    )
}

export default Session