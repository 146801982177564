import React, { useState, useRef, useEffect } from 'react'
import { Box, styled, Typography, useTheme,InputBase,IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import SendIcon from '@mui/icons-material/Send';
import { socket } from '../../../config/SocketApi'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Attendee from './Attendee';
import Comment from './Comment';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
const ChatArea = ({ room_id }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const [sessionStarted, setSessionStarted] = useState(false)
    const [messages, setMessages] = useState([])
    const [lastMessage, setLastMessage] = useState('')
    const [privateMessage, setPrivateMessage] = useState();
    const [attendees, setAttendees] = useState([]);
    const sessionStartedRef = useRef(false);
    const chatAreaContainerRef = useRef();
    const [autoFocus, setAutoFocus] = useState(false)
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
    const ChatAreaContainer = styled(Box)({

        // flex: 14,
        //display: 'none',
        flexDirection: 'column',
        // maxHeight: 'calc( 100vh - 60px )'
        height: '100%'
        // maxHeight: ((sessionStartedRef.current || sessionStarted) ? (user?.user_type !== 0 ? 'calc(100vh - 360px)' : 'calc(100vh - 260px)') : (user?.user_type !== 0 ? 'calc(100vh - 160px)' : 'calc(100vh - 60px)')),
    })

    const ClassRoomAttendeeBox = styled(Box)({
        maxHeight: '150px',
        height: '150px',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.text.secondary}`,

        minHeight: '100px',
        overflowY: 'scroll'
    })
    const ClassRoomAtendeeHeader = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 24px 4px 24px',
    })

    const ClassRoomAttendees = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })

    const ClassRoomChatBox = styled(Box)({
        flex: 6,
        border: `1px solid ${theme.palette.text.secondary}`,
        height: ((sessionStartedRef.current || sessionStarted) ? (user?.user_type !== 0 ? 'calc(100vh - 580px)' : 'calc(100vh - 480px)') : (user?.user_type !== 0 ? 'calc(100vh - 380px)' : 'calc(100vh - 280px)')),
        maxHeight: ((sessionStartedRef.current || sessionStarted) ? (user?.user_type !== 0 ? 'calc(100vh - 580px)' : 'calc(100vh - 480px)') : (user?.user_type !== 0 ? 'calc(100vh - 380px)' : 'calc(100vh - 280px)')),
        // maxHeight: (sessionStarted ? (user?.user_type !== 0 ? (window.innerHeight - 580) : (window.innerHeight - 480)) : (user?.user_type !== 0 ? (window.innerHeight - 280) : (window.innerHeight - 180))) + 'px',
        overflowY: 'scroll',
        minHeight: '100px',
    })

    const ClassRoomChatInputBox = styled(Box)({
        flex: 0.5,
        display: 'flex',
        padding: '5px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${theme.palette.text.secondary}`,
    })
    const ChatBox = styled(Box)({
        padding: '10px 10px',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        border: `1px solid ${theme.palette.background.gray}`,
        backgroundColor: theme.palette.background.gray,
        borderRadius: '8px',
        maxWidth: '100%',
        wordBreak: 'normal',
        lineBreak: 'auto',
        position: 'relative'
    })
    const InputBox = styled(InputBase)({
        flex: 8,
        color: theme.palette.text.default,
        backgroundColor: theme.palette.background.gray,
        outline: 'none',
        border: 'none',
        wordBreak: 'normal',
        lineBreak: 'auto'
    })
    const ButtonBox = styled(Box)({
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        justifyItems: 'flex-start',
        alignItems: 'center',
        width: '30%'
    })
    const messagesRef = useRef()
    useEffect(() => {
        socket.emit("send-attendees", {})
        if (!user) return
        socket.on("attendees", users => {
            setAttendees(users)
        })

        return () => {
            socket.off("attendees")
        }
    }, [user])

    const handAddMessage = (message, userName, userType, timeSent, fullName, imgPath, isPrivate = false) => {
        setMessages(prev => [...prev, { message, userName, userType, timeSent, isPrivate, imgPath, fullName }])
    }

    const handleLastMessage = (lastMessage) => {
        setLastMessage(lastMessage)
        // setEmojiPickerOpen(false)
    }

    useEffect(() => {
        socket.on("tutor-cam-enabled", (condition) => {
            console.log("the condition", condition, messagesRef.current, user)
            // messagesRef.current.style.height = (condition ? (user?.user_type !== 0 ? 'calc(100vh - 580px)' : 'calc(100vh - 480px)') : (user?.user_type !== 0 ? 'calc(100vh - 380px)' : 'calc(100vh - 280px)'))
            // chatAreaContainerRef.current.style.height = (condition ? (user?.user_type !== 0 ? 'calc(100vh - 360px)' : 'calc(100vh - 260px)') : (user?.user_type !== 0 ? 'calc(100vh - 160px)' : 'calc(100vh - 60px)'))
            setSessionStarted(condition)
            sessionStartedRef.current = condition;
        })
        socket.on("private-message", (message, userName, userType, timeSent, fullName, imgPath) => {
            handAddMessage(message, userName, userType, timeSent, fullName, imgPath, true)
        })
        socket.on("recieve-message", (message, userName, userType, timeSent, fullName, imgPath) => {
            handAddMessage(message, userName, userType, timeSent, fullName, imgPath)

        })

        return () => {
            socket.off("private-message");
            socket.off("tutor-cam-enabled");
            socket.off("public-message");
        }
    }, [])
    useEffect(() => {
        socket.on("got-connected-student-mic", (connected_student) => {
            const micConnectedAttendees = attendees.map(a => {
                return { ...a, micConnected: a?.micConnected || a?.user_id === connected_student?.user_id }
            })
            setAttendees(micConnectedAttendees)
        })
        return () => {
            socket.off("got-connected-student-mic")
        }
    }, [attendees])
    const handleSentPrivateMessage = (u_id, user_name) => {
        setPrivateMessage({ u_id, user_name })
    }
    useEffect(() => {
        messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
        // const videoEl = document.querySelector("#live_stream_video");
        // const sessionStartedByEl = videoEl?.style?.display !== 'none'
        // setSessionStarted(sessionStartedByEl);
        if(lastMessage !==""){
            setAutoFocus(true)
        }else{
            setAutoFocus(false)
        }
    }, [messages, lastMessage, sessionStarted])
    const handleSendMessage = () => {
        if (lastMessage !== "") {
            var date = new Date();
            const timeSent = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
            if (privateMessage) {
                console.log("privat messtge sent ", lastMessage, user.name, user.user_type, timeSent, privateMessage.u_id, user.full_name, user.img_path)
                socket.emit("private-message", lastMessage, user.name, user.user_type, timeSent, privateMessage.u_id, user.full_name, user.img_path);
                setPrivateMessage()
            } else {
                socket.emit("public-message", lastMessage, user.name, user.user_type, timeSent, user.full_name, user.img_path);
            }
            handAddMessage(lastMessage, user.name, user.user_type, timeSent, user.full_name, user.img_path);
            handleLastMessage("")
        }
        setEmojiPickerOpen(false)
    }
    const handleKeypress = e => {
        if (e.code === 'Enter') {
            handleSendMessage();
        }
    };
    const inputRef = useRef();
    return (
        <ChatAreaContainer ref={chatAreaContainerRef}>
            <ClassRoomAttendeeBox>
                <ClassRoomAtendeeHeader>
                    <Typography>{t("attendeelist")} ({attendees.length})</Typography>
                    {user && user.user_type !== 0 && <ExitToAppIcon sx={{ color: theme.palette.primary.main }} />}
                </ClassRoomAtendeeHeader>
                <ClassRoomAttendees>
                    {attendees.map(attendee => <Attendee data={attendee} room_id={room_id} onaddPrivateMessage={(user_id, user_name) => handleSentPrivateMessage(user_id, user_name)} />)}
                </ClassRoomAttendees>
            </ClassRoomAttendeeBox>
            <ClassRoomChatBox ref={messagesRef}>
                <Typography sx={{ margin: '4px 24px 4px 24px', }}>{t("chatbox")} {messages.length !== 0 ? `(${messages.length})` : ''}</Typography>
                {messages.map(message => (<Comment data={message} user={user} />))}
            </ClassRoomChatBox>
            <ClassRoomChatInputBox>
                <ChatBox>
                    {privateMessage && <Chip sx={{ position: 'absolute', zIndex: 1000, top: '-25px', right: 0, paddingLeft: '10px' }} label={t('private_message_to') + ' ' + privateMessage.user_name} variant='filled' color='warning' onDelete={() => onPrivateMessageSent()} />}
                    <InputBox onClick={()=>setAutoFocus(true)} onKeyDown={(e) => handleKeypress(e)} dir={theme.direction} ref={inputRef} placeholder={t("sendmessage")} autoFocus={autoFocus} value={lastMessage} onChange={(e) => { setLastMessage(e.target.value) }}></InputBox>
                    {emojiPickerOpen && <Box sx={{ position: 'absolute', top: "-300px", right: "0px", zIndex: 1000 }}>
                        <EmojiPicker
                            width="100%"
                            height={300}
                            searchDisabled={true}
                            lazyLoadEmojis={true}
                            onEmojiClick={(e) => handleLastMessage(prev => prev + e.emoji)}
                            emojiStyle={EmojiStyle.NATIVE}
                        >
                        </EmojiPicker>
                    </Box>}
                    {user.user_type === 0 ? <ButtonBox>

                        <IconButton sx={{ margin: 0, padding: 0.3 }} onClick={() => setEmojiPickerOpen(prev => !prev)}><EmojiEmotionsIcon sx={{ width: 15, margin: 0 }} /></IconButton>
                        {/* <IconButton sx={{ margin: 0, padding: 0.3 }} onClick={() => handleRaiseHand()}><BackHandIcon sx={{ width: 15, margin: 0 }} /></IconButton> */}
                        <IconButton sx={{ margin: 0, padding: 0.3 }} onClick={() => handleSendMessage()}>
                            <SendIcon sx={{ width: 15, margin: 0 }} />
                        </IconButton>
                    </ButtonBox> :
                        <>
                            <IconButton onClick={() => setEmojiPickerOpen(prev => !prev)}><EmojiEmotionsIcon /></IconButton>
                            <IconButton onClick={() => handleSendMessage()}>
                                <SendIcon />
                            </IconButton>
                        </>
                    }
                </ChatBox>
                {/* <ChatInput onPrivateMessageSent={() => setPrivateMessage()} privateMessage={privateMessage} lastMessage={lastMessage} onAddComment={(message, userName, userType, timeSent, fullName, imgPath) => handAddMessage(message, userName, userType, timeSent, fullName, imgPath)} onChangeLastMessage={(lastMessage) => handleLastMessage(lastMessage)} /> */}
            </ClassRoomChatInputBox>
        </ChatAreaContainer>
    )
}

export default ChatArea