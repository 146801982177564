import React, { useState } from 'react'
import Footer from '../master/components/Footer'
import MasterAppBar from '../master/components/MasterAppBar'
import { Box, styled, Typography, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Rating from '@mui/material/Rating';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import LinkIcon from '@mui/icons-material/Link';
import CourseFinalTest from '../Course/components/CourseFinalTest';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Comment from './components/Comment';
import ChatInput from '../virtualClassRoom/components/ChatInput';
import AddReview from './components/AddReview';
import Review from './components/Review';
import { useTranslation } from 'react-i18next'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Session() {
    const { t } = useTranslation()
    const theme = useTheme();
    const AntTabs = styled(Tabs)(({ theme }) => ({
        marginTop: '2rem',
        borderBottom: '1px solid #e8e8e8',
        justifyContent: 'space-between',

        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.text.default,
        }, '& .MuiTabs-flexContainer': {
            justifyContent: 'space-around',

        },
    }));

    const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.5em',
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,


        '&.Mui-selected': {
            color: theme.palette.text.default,
            fontWeight: theme.typography.fontWeightMedium,
        },

    }));

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const SessionContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '20px',
        padding: '2rem 5rem',
    })
    const SessionInfo = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px'
    })
    const ReviewContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px'
    })
    const DiscussionBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px',
        border: `1px solid ${theme.palette.background.gray}`,
        borderRadius: `0px 0px 5px 5px`
    })
    const CommentsBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px',
        padding: '10px',
        borderTop: `1px solid ${theme.palette.background.gray}`,
        borderBottom: `1px solid ${theme.palette.background.gray}`
    })
    const Comments = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px'
    })
    const ReviewBox = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '30px'

    })
    const ReviewLeftBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px',
        flex: 3
    })
    const ReviewRightBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        flex: 1,
        padding: '3rem 2rem',
        border: `1px solid ${theme.palette.background.gray}`,
        borderRadius: '10px',
        maxHeight: '200px',
        maxWidth: '200px'

    })
    const ReviewsBox = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '10px',
        padding: '10px',
        width: '100%',
        border: `1px solid ${theme.palette.background.gray}`,
        borderRadius: '5px',
        maxHeight: '400px',
        overflowY: 'scroll'

    })
    const ToturInfo = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px'
    }))
    const LineBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '0px',
        position: 'relative'
    }))
    const Line = styled(Box)(({ theme }) => ({
        border: `1px solid ${theme.palette.text.default}`,
        position: 'absolute',
        left: theme.direction === 'ltr' ? "-5rem" : "0px",
        right: theme.direction === 'ltr' ? "0px" : "-5rem",
        width: "calc(100% + 5rem)",
        backgroundColor: theme.palette.text.default
    }))
    const Dott = styled(Box)(({ theme }) => ({
        border: `1px solid ${theme.palette.text.default}`,
        backgroundColor: theme.palette.text.default,
        width: '8px',
        height: '8px',
        borderRadius: '50%',

    }))
    const SessionMetrics = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '10px'
    }))

    const questions = [
        {
            questionId: 1,
            question: 'Where is the Nile river?',
            answers: [
                {
                    id: 1,
                    answer: 'Egypt',
                    status: true,
                }, {
                    id: 2,
                    answer: 'Syria',
                    status: false,
                }, {
                    id: 3,
                    answer: 'Jordan',
                    status: false,
                }
            ]
        }, {
            questionId: 2,
            question: 'What is the name of the tallest tower?',
            answers: [
                {
                    id: 1,
                    answer: 'Eifel Twoer',
                    status: false,
                }, {
                    id: 2,
                    answer: 'Khalifa Tower',
                    status: true,
                }, {
                    id: 3,
                    answer: 'Pizza Tower',
                    status: false,
                }
            ]
        }
    ]
    const reviews = [
        {
            username: "Hadi Dahi",
            review: 'this is Ok!',
            stars: 3,
            date: '2 Jnuary 2023'
        }, {
            username: "Ghayth Ahmad",
            review: 'this is fine!',
            stars: 1,
            date: '2 February 2022'
        }, {
            username: "Ola Ahmad",
            review: 'this is good!',
            stars: 4,
            date: '2 March 2022'
        }, {
            username: "Saleh Ahmad",
            review: 'this is the best!',
            stars: 5,
            date: '2 February 2022'
        }, {
            username: "Ghayth Ahmad",
            review: 'this is fine!',
            stars: 1,
            date: '2 February 2022'
        },


    ]
    return (
        <ScopedCssBaseline>
            <Box>
                <MasterAppBar crumb={true} />

                <SessionContainer>
                    <SessionInfo>
                        <ToturInfo>
                            <PersonIcon />
                            <Typography variant='h4' sx={{ color: theme.palette.text.secondary }}>Hadi Dahi</Typography>
                        </ToturInfo>
                        <Typography variant='h5' sx={{ color: theme.palette.text.secondary, marginLeft: '30px' }}>Learn python and how to use it to analyze,visualize and present data. Includes tons of sample code and hours of video!</Typography>
                        <SessionMetrics>
                            <SignalCellularAltIcon />
                            <Rating name="read-only" value={4} readOnly />
                            <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>(13,520)</Typography>
                        </SessionMetrics>
                    </SessionInfo>
                    <Box>
                        <AntTabs
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                        >
                            <AntTab value={0} label={t("descussion")} />
                            <AntTab value={1} label={t("links")} />
                            <AntTab value={2} label={t("attachments")} />
                            <AntTab value={3} label={t("quiz")} />
                        </AntTabs>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <DiscussionBox>
                                    <Typography variant='h5' sx={{ color: theme.palette.text.secondary, margin: '10px' }}>Aliquet fermentum velit bibendum volutpat erat. Gravida tortor habitant mauris sed neque et feugiat felis nulla. Aenean condimentum amet massa augue tempor. Aenean convallis odio urna sit risus imperdiet.</Typography>
                                    <CommentsBox>
                                        <Typography variant='h5' sx={{ color: theme.palette.text.secondary }}>{t("comments")} (20)</Typography>
                                        <Comments>
                                            <Comment />
                                            <Comment />
                                            <Comment />
                                            <Comment />
                                            <Comment />
                                        </Comments>
                                    </CommentsBox>
                                    <Box p="0px 10px 10px 10px">
                                        <ChatInput />
                                    </Box>

                                </DiscussionBox>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '10px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <LinkIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.default }}>https://torbet.com</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <LinkIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.secondary }}>https://perla-tech.com</Typography></Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <LinkIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.secondary }}>https://shamcoders.com</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <LinkIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.secondary }}>https://google.com</Typography>
                                    </Box>

                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '10px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <FilePresentIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.default }}>https://torbet.com</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <FilePresentIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.secondary }}>https://perla-tech.com</Typography></Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <FilePresentIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.secondary }}>https://shamcoders.com</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                        <FilePresentIcon sx={{ color: theme.palette.text.secondary }} />
                                        <Typography sx={{ color: theme.palette.text.secondary }}>https://google.com</Typography>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={3} dir={theme.direction}>
                                <CourseFinalTest data={questions} />
                            </TabPanel>
                        </SwipeableViews>
                    </Box>
                    <ReviewContainer>
                        <Box sx={{ width: 'max-content', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h2' component="span" sx={{ width: 'max-content' }}>{t("reviews")}</Typography>
                            <LineBox >
                                <Line /><Dott />
                            </LineBox>
                        </Box>
                        <ReviewBox>

                            <ReviewLeftBox>
                                <AddReview />
                                <ReviewsBox>
                                    {reviews.map(review => (<Review data={review} />))}
                                </ReviewsBox>
                            </ReviewLeftBox>
                            <ReviewRightBox>
                                <Typography variant='h1'>4.0</Typography>
                                <Rating name="read-only" value={4} readOnly />
                            </ReviewRightBox>
                        </ReviewBox>

                    </ReviewContainer>
                </SessionContainer>

                <Footer />
            </Box>
        </ScopedCssBaseline>
    )
}

