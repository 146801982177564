import React, { useState, useRef, useEffect } from 'react'
import { Stage, Layer, Line, Rect, Ellipse, Shape } from 'react-konva';
import { Box, styled, useTheme, IconButton, Tooltip } from '@mui/material'
import ColorPicker from './ColorPicker';
import PenIcon from '@mui/icons-material/Create';
import RectangleIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TriangleIcon from '@mui/icons-material/ChangeHistory';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircleIcon from '@mui/icons-material/PanoramaFishEye';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
const DrawingBoardShareScreen = ({ dimensions }) => {
    const theme = useTheme();
    const [selectedColor, setSelectedColor] = useState("#000")
    const [selectedTool, setSelectedTool] = useState({ label: 'Pen', icon: <PenIcon /> })
    const [selectedStrock, setSelectedStrock] = useState(false)
    const [lines, setLines] = useState([]);
    const [rectangles, setRectangles] = useState([]);
    const [triangles, setTriangles] = useState([]);
    const [ellipses, setEllipses] = useState([]);
    const [strightLines, setStrightLines] = useState([]);
    const [markerLines, setMarkerLines] = useState([]);
    const [showCanvas, setShowCanvas] = useState(false);
    const [stageWidth, setStageWidth] = useState(200);
    const [stageHeight, setStageHeight] = useState(200);
    const [strokeWidth, setStrokeWidth] = useState(2)
    const isDrawing = useRef(false);
    const menuBoxRef = useRef()
    const strockMenuBoxRef = useRef()
    const menuAnchorRef = useRef()
    const storckMenuAnchorRef = useRef()
    const handleOpenMenu = () => {
        menuBoxRef.current.style.visibility = 'visible';
        menuBoxRef.current.style.top = menuAnchorRef.current.offsetTop + 25 + 'px';
        menuBoxRef.current.style.left = theme.direction === 'rtl' ? menuAnchorRef.current.offsetLeft - 25 + 'px' : menuAnchorRef.current.offsetLeft + 25 + 'px';
    };
    const handleOpenStrockMenu = () => {
        strockMenuBoxRef.current.style.visibility = 'visible';
        strockMenuBoxRef.current.style.top = strockMenuBoxRef.current.offsetTop + 25 + 'px';
        strockMenuBoxRef.current.style.left = theme.direction === 'rtl' ? strockMenuBoxRef.current.offsetLeft - 25 + 'px' : strockMenuBoxRef.current.offsetLeft + 25 + 'px';
    };
    const handleMenuClose = () => {
        menuBoxRef.current.style.visibility = 'hidden';
    };
    const handleStrockMenuClose = () => {
        strockMenuBoxRef.current.style.visibility = 'hidden';
    };
    const handleShowCanvas = () => {
        setShowCanvas(true)
    }
    const handleOptionClick = (option) => {
        setSelectedTool(option);
        handleMenuClose();
    };
    const handleStrockOptionClick = (option) => {
        setSelectedStrock(option);
        handleStrockMenuClose();
    };
    const handleMouseDown = (e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        if (selectedTool) {
            switch (selectedTool.label) {
                case 'Pen':
                    setLines([...lines, { tool: selectedTool.label, points: [pos.x, pos.y], color: selectedColor, strokeWidth }]);
                    break;
                case 'Rectangle':
                    setRectangles([...rectangles, { tool: selectedTool.label, type: 'rectangle', x: pos.x, y: pos.y, width: 0, height: 0, color: selectedColor, strokeWidth }])
                    break;
                case 'Triangle':
                    setTriangles([...triangles, { tool: selectedTool.label, type: 'triangle', firstPoint: [pos.x, pos.y], secondPoint: [pos.x, pos.y], thirdPoint: [pos.x, pos.y], color: selectedColor, strokeWidth }])
                    break;
                case 'Ellipse':
                    setEllipses([...ellipses, { tool: selectedTool.label, type: 'ellipse', x: pos.x, y: pos.y, radiusX: 0, radiusY: 0, color: selectedColor, strokeWidth }])
                    break;
                case 'Line':
                    setStrightLines([...strightLines, { tool: selectedTool.label, type: 'line', points: [pos.x, pos.y], color: selectedColor, strokeWidth }])
                    break;
                case 'Marker':
                    setMarkerLines([...markerLines, { tool: selectedTool.label, points: [pos.x, pos.y], color: selectedColor, strokeWidth: 15 }]);
                    break;
            }
        }

    };
    const setClickable = () => {
        window.electron.setClickable();
    }
    const setClickThrough = () => {
        window.electron.setClickThrough();
    }
    const handleMouseMove = (e) => {
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();

        const point = stage.getPointerPosition();
        switch (selectedTool.label) {
            case 'Pen':
                let lastLine = lines[lines.length - 1];
                lastLine.points = lastLine.points.concat([point.x, point.y]);
                lines.splice(lines.length - 1, 1, lastLine);
                setLines(lines.concat());
                break;
            case 'Rectangle':
                let lastRectangle = rectangles[rectangles.length - 1];
                lastRectangle.width = point.x - lastRectangle.x > 0 ? point.x - lastRectangle.x : 0
                lastRectangle.height = point.y - lastRectangle.y > 0 ? point.y - lastRectangle.y : 0
                rectangles.splice(rectangles.length - 1, 1, lastRectangle);

                setRectangles(rectangles.concat());
                break;
            case 'Triangle':
                let lastTriangle = triangles[triangles.length - 1];
                lastTriangle.secondPoint = [point.x, point.y]
                lastTriangle.thirdPoint = [point.x - (point.x - lastTriangle.firstPoint[0]) * 2, point.y]
                triangles.splice(triangles.length - 1, 1, lastTriangle);

                setTriangles(triangles.concat());
                break;
            case 'Ellipse':
                let lastEllipse = ellipses[ellipses.length - 1];
                lastEllipse.radiusX = point.x - lastEllipse.x > 0 ? point.x - lastEllipse.x : 0;
                lastEllipse.radiusY = point.y - lastEllipse.y > 0 ? point.y - lastEllipse.y : 0;
                ellipses.splice(ellipses.length - 1, 1, lastEllipse);

                setEllipses(ellipses.concat());
                break;
            case 'Line':
                let lastStrightLine = strightLines[strightLines.length - 1];
                lastStrightLine.points = [lastStrightLine.points[0], lastStrightLine.points[1], point.x, point.y];
                strightLines.splice(strightLines.length - 1, 1, lastStrightLine);
                setStrightLines(strightLines.concat());
                break;
            case 'Marker':
                let lastMarker = markerLines[markerLines.length - 1];
                lastMarker.points = lastMarker.points.concat([point.x, point.y]);
                markerLines.splice(markerLines.length - 1, 1, lastMarker);
                setMarkerLines(markerLines.concat());
                break;
        }
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };
    const ClassRoomToolBox = styled(Box)({
        display: 'flex',
        height: '31px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        gap: '10px',
        position: 'absolute',
        left: dimensions.x + 95 + 'px',
        top: dimensions.y - 30 + 'px',
        backgroundColor: 'gray',
        zIndex: 1000,
        background: theme.palette.background.default,



    })
    const handleDeleteShapes = () => {
        setShowCanvas(false);
        setSelectedTool({ label: 'Pen', icon: <PenIcon /> });
        setLines([]);
        setRectangles([])
        setTriangles([])
        setEllipses([])
        setStrightLines([])
        setMarkerLines([])
    }
    const DrawingBoxContainer = styled(Box)({
        flex: 1,
        borderLeft: theme.direction === 'rtl' ? `1px solid ${theme.palette.text.secondary}` : 'none',
        borderRight: theme.direction === 'ltr' ? `1px solid ${theme.palette.text.secondary}` : 'none',

    })
    const SmallStrockBox = styled(Box)({
        width: '20px',
        height: '2px',
        backgroundColor: theme.palette.text.secondary,
        cursor: 'pointer',
        margin: '8px'

    })
    const MediumStrockBox = styled(Box)({
        width: '20px',
        height: '4px',
        backgroundColor: theme.palette.text.secondary,
        cursor: 'pointer',
        margin: '8px'

    })
    const LargStrockBox = styled(Box)({
        width: '20px',
        height: '6px',
        backgroundColor: theme.palette.text.secondary,
        cursor: 'pointer',
        margin: '8px'

    })
    const CustomMenuBox = styled(Box)({
        position: 'absolute',
        zIndex: 1100,
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        visibility: 'hidden',
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: '5px'
    })
    const containerRef = useRef();
    return (
        <DrawingBoxContainer ref={containerRef}>
            <ClassRoomToolBox>

                <IconButton ref={menuAnchorRef} onClick={handleOpenMenu} onMouseEnter={() => setClickable()} onMouseLeave={() => setClickThrough()}>
                    {selectedTool ? selectedTool.icon : <PenIcon sx={{ width: '15px' }} />}
                </IconButton>

                <CustomMenuBox ref={menuBoxRef}>

                    <IconButton onMouseEnter={() => setClickable()} onClick={() => handleOptionClick({ label: 'Pen', icon: <PenIcon /> })}>
                        <PenIcon />
                    </IconButton>
                    <IconButton onMouseEnter={() => setClickable()} onClick={() => handleOptionClick({ label: 'Marker', icon: <BorderColorIcon /> })}>
                        <BorderColorIcon />
                    </IconButton>
                    <IconButton onMouseEnter={() => setClickable()} onClick={() => handleOptionClick({ label: 'Line', icon: <HorizontalRuleIcon /> })}>
                        <HorizontalRuleIcon />
                    </IconButton>
                    <IconButton onMouseEnter={() => setClickable()} onClick={() => handleOptionClick({ label: 'Rectangle', icon: <RectangleIcon /> })}>
                        <RectangleIcon />
                    </IconButton>
                    <IconButton onMouseEnter={() => setClickable()} onClick={() => handleOptionClick({ label: 'Ellipse', icon: <CircleIcon /> })}>
                        <CircleIcon />
                    </IconButton>
                    <IconButton onMouseEnter={() => setClickable()} onClick={() => handleOptionClick({ label: 'Triangle', icon: <TriangleIcon /> })}>
                        <TriangleIcon />
                    </IconButton>
                </CustomMenuBox>

                <IconButton onMouseEnter={() => setClickable()} onMouseLeave={() => setClickThrough()} ref={storckMenuAnchorRef} onClick={handleOpenStrockMenu} sx={{ margin: 0, padding: 0 }}>
                    {selectedStrock ? selectedStrock.icon : <SmallStrockBox sx={{ margin: '0px' }} />}
                </IconButton>

                <CustomMenuBox ref={strockMenuBoxRef}>

                    <SmallStrockBox onMouseEnter={() => setClickable()} onClick={() => handleStrockOptionClick({ label: 'Small', icon: <SmallStrockBox sx={{ margin: '0px' }} />, strockWidth: 2 })}>
                    </SmallStrockBox>
                    <MediumStrockBox onMouseEnter={() => setClickable()} onClick={() => handleStrockOptionClick({ label: 'Medium', icon: <MediumStrockBox sx={{ margin: '0px' }} />, strockWidth: 4 })}>
                    </MediumStrockBox>
                    <LargStrockBox onMouseEnter={() => setClickable()} onClick={() => handleStrockOptionClick({ label: 'Large', icon: <LargStrockBox sx={{ margin: '0px' }} />, strockWidth: 6 })}>
                    </LargStrockBox>

                </CustomMenuBox>

                <ColorPicker shareScreen={true} onMouseEnter={() => setClickable()} onSelectColor={(color) => setSelectedColor(color)} colorSelected={selectedColor} />

                <IconButton onMouseEnter={() => setClickable()} onMouseLeave={() => setClickThrough()} onClick={() => showCanvas ? handleDeleteShapes() : handleShowCanvas()} sx={{ margin: 0, padding: 0 }}>
                    {showCanvas ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
            </ClassRoomToolBox>

            {showCanvas && stageHeight && stageWidth && containerRef && (
                <Box onMouseEnter={() => setClickable()} onMouseLeave={() => setClickThrough()}>
                    <Stage width={dimensions.width} height={dimensions.height} style={{ position: 'absolute', zIndex: 200, left: dimensions.x + "px", top: dimensions.y + 'px', display: "block", cursor: 'default' }}
                        onMouseDown={handleMouseDown}
                        onMousemove={handleMouseMove}
                        onMouseup={handleMouseUp}
                    >
                        <Layer>
                            {lines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke={line.color}
                                    strokeWidth={selectedStrock?.strockWidth ?? 2}
                                    tension={0.1}
                                    lineCap="round"
                                    lineJoin="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                />
                            ))}
                            {strightLines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke={line.color}
                                    strokeWidth={selectedStrock?.strockWidth ?? 2}
                                    tension={0.1}
                                    lineCap="round"
                                    lineJoin="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                />
                            ))}
                            {markerLines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke={line.color}
                                    strokeWidth={15}
                                    tension={0.1}
                                    opacity={0.3}
                                    lineCap='square'
                                    lineJoin="round"
                                    globalCompositeOperation={
                                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                                    }
                                />
                            ))}
                            {rectangles.map((rect, i) => (

                                <Rect key={i} x={rect.x} y={rect.y} width={rect.width} height={rect.height} stroke={rect.color} strokeWidth={selectedStrock?.strockWidth ?? 2} />

                            ))}
                            {triangles.map((triangle, i) => (
                                <Shape
                                    sceneFunc={(context, shape) => {
                                        context.beginPath();
                                        context.moveTo(triangle.firstPoint[0], triangle.firstPoint[1]);
                                        context.lineTo(triangle.secondPoint[0], triangle.secondPoint[1]);
                                        context.lineTo(triangle.thirdPoint[0], triangle.thirdPoint[1]);
                                        context.closePath();
                                        context.fillStrokeShape(shape);
                                    }}
                                    stroke={triangle.color}
                                    strokeWidth={selectedStrock?.strockWidth ?? 2}
                                />
                            ))}
                            {ellipses.map((ellipse, i) => (
                                <Ellipse
                                    key={i}
                                    x={ellipse.x}
                                    y={ellipse.y}
                                    radiusX={ellipse.radiusX}
                                    radiusY={ellipse.radiusY}
                                    stroke={ellipse.color}
                                    strokeWidth={selectedStrock?.strockWidth ?? 2}
                                />
                            ))}
                        </Layer>
                    </Stage></Box>
            )}
        </DrawingBoxContainer>

    )
}

export default DrawingBoardShareScreen