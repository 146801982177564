import React from 'react';
import { Box, styled, keyframes } from '@mui/material';
// import '../CSS/CourseCardSkeleton.css'; // Import CSS for animation

const Skeleton = ({ width, height, margin = '10px' }) => {
    const shimmerAnimation = keyframes`
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  `;
    const SkeletonContainer = styled(Box)(({ theme }) => ({
        width: width,
        height: height,
        margin: margin,
        borderRadius: '5px',
        overflow: 'hidden',
        background: theme.palette.background.default,
        position: 'relative',
    }))
    const SkeletonAnimator = styled(Box)(({ theme }) => {
        const lightColor = theme.palette.mode === 'light' ? 'rgba(200, 200, 200, 0.8)' : 'rgba(100, 100, 100, 0.1)';
        const darkColor = theme.palette.mode === 'light' ? 'rgba(200, 200, 200, 0)' : 'rgba(100, 100, 100, 0)';

        return {
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundImage: `linear-gradient(to right, ${darkColor} 0%, ${lightColor} 50%, ${darkColor} 100%)`,
                animation: `${shimmerAnimation} 1.5s infinite`
            }
        }

    })
    return (
        <SkeletonContainer>
            <SkeletonAnimator />
        </SkeletonContainer>
    );
};

export default Skeleton;