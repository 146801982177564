import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    whiteBoardDimensions: null,
};

const screenSharingSlice = createSlice({
    name: 'screenSharing',
    initialState,
    reducers: {
        setWhiteBoardDimentions: (state, { payload }) => {
            state.whiteBoardDimensions = payload.dimensions;
        }
    },
});

export const { setWhiteBoardDimentions } =
    screenSharingSlice.actions;

export default screenSharingSlice.reducer;
