export function isMobileBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Check for mobile browsers
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
  
    if (/Windows Phone|webOS|BlackBerry|BB10|PlayBook|Silk|Mobile Safari|Opera Mini|Opera Mobi/.test(userAgent)) {
      return true;
    }
  
    return false;
  }