import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n/i18n';
import * as process from 'process';

window.global = window;
window.process = process;
window.Buffer = [];
createRoot(document.getElementById("root")).render(<App />);

