import { Box, Typography, useTheme, styled } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useContext, useState, useEffect } from 'react'
import Event from './Event';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadCrumbContext } from '../../master/context/BreadCrumbContext';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import Skeleton from './helpers/Skeleton';
import API from '../../../config/API';
import { useLogout } from '../../auth/hooks/useLogout';
function Events() {
    const theme = useTheme();
    const { user } = useAuthContext() || JSON.parse(localStorage.getItem("user"));
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const { breadCrumbAdder, breadCrumbSetter } = useContext(BreadCrumbContext)
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { logout } = useLogout();
    const handleLogOut = () => {
        logout();
        breadCrumbSetter(0)
        navigate("/")
    };
    const NoContent = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20rem',
        padding: '2rem'
    }));
    const ContainerBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
        width:'90%',
        

    }));

    const MoreBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',

    }));
    const InnerBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        // justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        flex: 1,
        width:'90%',
        
    }));
    const getEventsData = () => {
        setLoading(true)
        API.get('events?page=1&limit=10', {
            headers: {
                Authorization: user.token,
                "Content-Type": 'application/json'
            }
        }).then(data => {
            if (data.status === 200 && data?.data?.data.length) {
                setEvents(data.data.data)
            }
            setLoading(false)
        }).catch(err => {
            console.log("the axios error is ", err)
            if (err?.response?.status === 403 || err.code === 'ERR_NETWORK') {
                handleLogOut()
            }

            setLoading(false)
        })
    }
    useEffect(() => {
        if (user) getEventsData()
    }, [user])
    const handleNavigation = () => {
        breadCrumbAdder({ translatable: true, title: 'events', route: '/all_events' })
        navigate('/all_events')
    }
    return (
        <ContainerBox >
            <InnerBox>
                <Typography variant='h2' sx={{ ml: '0.2rem', color: theme.palette.text.secondary }}>{t("events")}</Typography>
                {(loading && !events.length) ? <>
                    {Array(3).fill().map((_, index) => <Skeleton key={index} width={'20rem'} height={'5rem'} />)}

                </> : <>
                    {events.length > 0 ? <>
                        {events.map(ev => (
                            <Event key={ev.id} data={ev} />
                        ))}
                        {/* {events.length >= 4 &&
                            <MoreBox onClick={() => handleNavigation()}>
                                <Typography variant='h4' sx={{ color: theme.palette.primary.main }}>{t("more")} &nbsp; </Typography>
                                {theme.direction === 'ltr' ? (<ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />) : (<ArrowBackIcon sx={{ color: theme.palette.primary.main }} />)}
                            </MoreBox>} */}
                    </> : <NoContent>
                        <img src={`${process.env.PUBLIC_URL}/assets/no_events.png`} style={{ width: '15rem' }} />
                        {(user?.user_type !== 0) ?
                            <Link target='_blank' to='https://torbet.org'
                                style={{ gap: '5px', display: 'flex', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }} >
                                {t('createCourse')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>
                            :
                            <Link target='_blank' to="https://torbet.org"
                                style={{ display: 'flex', gap: '5px', justifyItems: 'center', alignItems: 'center', textDecoration: 'none', color: theme.palette.secondary.main }}>
                                {t('exploreCourses')} {theme.direction === 'ltr' ? <ArrowForwardIcon /> : <ArrowBackIcon />}</Link>}
                    </NoContent>}
                </>}
            </InnerBox>
        </ContainerBox>
    )
}

export default Events