import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    meetingStarted: false,
};

const meetingSlice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        toggleMeetingState: (state, { payload }) => {
            state.meetingStarted = payload.status;
        }
    },
});

export const { toggleMeetingState } =
    meetingSlice.actions;

export default meetingSlice.reducer;
