import { Box, IconButton, Typography, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react'

const AdmitionRequests = ({message}) => {
  const theme = useTheme();
  return (
    <>
    {message &&<Box sx={{position:'absolute', bottom:0,right:0,zIndex:1000, backgroundColor:theme.palette.background.default, padding:'0.4rem',border:`1px solid ${theme.palette.text.primary}`}}>
            <Typography>{message}</Typography>
    </Box>}
    </>
    
  )
}

export default AdmitionRequests